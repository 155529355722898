.sosafe-DownloadPreview {
    display: flex;
    justify-content: center;
    align-items: stretch;
    min-height: 0;
}

.sosafe-DownloadPreview-image {
    width: 100%;
	max-height: 100%;
    height: 600px;
    filter: drop-shadow(0 2px 6px rgba(0,0,0,0.2));
}
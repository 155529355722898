.loginPage__authMethods {
  margin: 24px 0 10px;
  display: flex;
  justify-content: center;
  gap: var(--sosafe-spacing-8);
  flex-direction: column;
}

.loginPage__ssoGooglePrivacy {
  text-align: center;
  line-height: 1.1;
  margin-top: var(--sosafe-spacing-8);
}


.loginPage__googleButtonArea {
  display: flex;
  justify-content: center;
  flex-direction: column;
}


.loginPage__googleSigninArea {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

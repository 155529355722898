@import "../../scss/theme";

.info-bubble {
  background-color: var(--sosafeThemed-color-secondary);
  border-radius: 50%;
  cursor: help;
  height: 1.75rem;
  width: 1.75rem;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;

  &.small {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.info-bubble-tooltip {
  width: 345px;
  max-width: 98vw;
  text-align: left;
}

@include media-breakpoint-down(sm) {
  .info-bubble-tooltip {
    max-width: 95vw;

    ul {
      padding-inline-start: 2rem;
    }
  }
}

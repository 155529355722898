button {
  all: unset;
}

button:focus {
  outline: revert;
}

.title {
  margin-top: 24px;
  margin-bottom: 0;
  color: var(--neutral-gray-gray-100, #494949);
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32.2px;
  text-align: center;
}

.container {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formContainer {
  display: flex;
  flex-direction: column;
}

.formSubContainer {
  display: flex;
  flex-direction: row;
  gap: var(--sosafe-spacing-12);
  margin-bottom: var(--sosafe-spacing-24);
  flex-grow: 1;
}

.formSubContainer>div {
  width: calc(50% - 10px);
}

.back {
  position: fixed;
  left: 40px;
  display: flex;
  justify-content: center;
  align-self: center;
}

.back>svg {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-right: 6px;
}

.back>span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.formSubContainer>div>div>label {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.saveContainer {
  display: flex;
  justify-content: end;
}

.save {
  font-size: 16px;
  padding: 6px 16px;
  border: 1px solid #5dbbba;
  background: var(--color-background-button-primary-disabled, #93d2d1);
}

.svg {
  position: absolute;
  top: 450px;
  z-index: 0;
}

.svg path {
  stroke: var(--sosafe-color-secondary--100);
}

.svg line {
  stroke: var(--sosafe-color-secondary--100);
}

.passwordCard {
  width: 100%;
  max-width: 784px;
  margin-top: 48px;
  padding: var(--spacing-11, 40px);
  overflow: visible;
  z-index: 1;
}

.passwordCard input {
  margin-bottom: var(--sosafe-spacing-16);
}

.formDropdown>label {
  font-size: 14px;
  margin-bottom: 6px;
  line-height: 18px;
  font-weight: 500;
  color: var(--label-color, var(--sosafe-color-gray--80));
  font-style: inherit;
}

@media screen and (max-width: 992px) {
  .svg {
    width: 900px;
  }
}

@media screen and (max-width: 480px) {
  .card {
    width: 283px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 200px;
  }

  .saveContainer {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }

  .formSubContainer {
    flex-direction: column;
  }

  .formSubContainer>div {
    width: 100%;
  }

  .svg {
    left: -5px;
    top: 610px;
    width: 900px;
  }

  .back {
    position: absolute;
    left: 0;
  }

  .title {
    font-size: 24px;
  }
}

@import './theme';

.btn.disabled, .btn:disabled {
  background-color: $gray-300 !important;
  border: $gray-300 !important;
  color: $dark;
  box-shadow: 0 0 0 0.2rem rgba(43, 125, 159, 0);
  cursor: default;
}

.btn-secondary:not([disabled]) {
  color: $button-text !important;
}

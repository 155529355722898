.sosafe-PoliciesStepper-step {
    font-weight: bold;
    font-size: var(--sosafe-typo-medium);
    padding: 0 0 0 1px; /* roboto specific fix for this font-size */
    line-height: 1;
    color: var(--sosafe-color-gray--100);

    margin: 0;
    min-width: 2rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    border: 1px solid var(--sosafe-color-gray--50);
    background: var(--sosafe-color-gray--10);

    display: flex;
    justify-content: center;
    align-items: center;
}

.sosafe-PoliciesStepper-step.is-visited {
    background: transparent;
    border-color: var(--sosafe-color-primary--100);
    color: var(--sosafe-color-primary--100);
}
.sosafe-PoliciesStepper-step.is-error {
    background: transparent;
    border-color: var(--sosafe-alertColor-danger--100);
    color: var(--sosafe-alertColor-danger--100);
}
.sosafe-PoliciesStepper-step.is-active {
    background: var(--sosafe-color-primary--100);
    border-color: var(--sosafe-color-primary--100);
    color: white;
}

.sosafe-PoliciesStepper-spacer {
    margin: 0;
    padding: 0;
    height: .125rem;
    width: 2.5rem;
    background: var(--sosafe-color-lightGray);
}

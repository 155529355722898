.container {
  height: 12rem;
  width: 288px;
  overflow: hidden;
  border-radius: 5px;
  transform: translate(0, 100%);
  position: absolute;
  right: 0;
  bottom: 12rem;
}

@keyframes modulePreview {
  0% {
    transform: translate(110%, 0);
  }


  50% {
    transform: translate(110%, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

.content {
  position: relative;
  transform: translate(0, 0);
  animation-name: modulePreview;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;

  .playIcon {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  img {
    width: 21rem;
    height: auto;
  }

  .topBunner {
    padding: 5px;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    text-align: center;
    background-color: rgb(0 0 0 / 50%);

    button {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 20px;
      height: 20px;
    }
  }

  .bottomBunner {
    padding: 5px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    text-align: center;
    background-color: rgb(0 0 0 / 50%);
  }

}

.header {
  height: 64px;
  background-color: var(--sosafe-color-secondary--100);
  text-align: center;
  border-radius: var(--sosafe-spacing-16) var(--sosafe-spacing-16) 0 0;
  padding: 16px;
}

.header h4 {
  color: var(--sosafeThemed-color-secondary--140, #fff);
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.8px;
}

.tour-overlay {
  left: 0;
  top: 0;
  z-index: 1000;
}

.tour-mobile-tooltip {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.5rem 1.75rem;
  width: 800px;
  max-width: 95%;
  max-height: 95%;
  overflow: auto;

  &.hide {
    opacity: 0;
  }

  &.show {
    opacity: 1;
  }

  transition: opacity .5s;
}

.navigation-container {
  width: 40px;
}

.text-container-tour {
  min-height: 10rem;
}

.mobile-tour-title {
  min-height: 2.6rem;
}

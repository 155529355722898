.sosafe-modulechanger{
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    padding: 0.5rem;
    max-height: 85vh;
    overflow-y: scroll;
}

.sosafe-modulechanger-categories{
    padding:0.25rem;
    margin-bottom: 0.5rem;
    display: flex;
    flex-wrap: wrap;
}
.sosafe-modulechanger-categories:hover {
    background-color: var(--sosafe-color-primary--10);
    border-radius: 0.25rem;
}
.sosafe-modulechanger-checkbox-layout{
    padding:0 0 0 0.5rem;
    display: flex;
    align-items: stretch;
}
.sosafe-modulechanger-checkbox{
    margin-left: 0.25rem;
}
.sosafe-modulechanger-lockIcon{
    margin-top: 0.1rem;
    margin-left: 0.5rem;
    color: var(--sosafe-alertColor-info--120)
}
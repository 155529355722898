.container {
  display: flex;
  flex-direction: column;
  align-items: normal;
  width: 300px;
  height: 150px;
  padding: 16px;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  overflow: hidden;
  height: 100%;
}

.name {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.details {
  display: flex;
  flex-direction: row;
  place-content: center space-between;
}

.detailsLeft {
  display: flex;
  flex-direction: row;
  place-content: center center;
  gap: 8px;
}

.tag {
  display: flex;
  align-self: center;
  line-height: 18px;
  gap: 4px;
}

.duration {
  align-self: center;
  color: #697077;
  display: flex;
  gap: 4px;
}

.icon,
.durationText {
  color: var(--mantine-color-gray-7);
}

.moduleLocked {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  background: rgb(0 0 0 / 50%);
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
}

.doNotDisplay {
  display: none;
  z-index: 0;
}

.lockerBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.lockerIcon {
  width: 36px;
  height: 36px;
  padding: 7px;
  border-radius: 19.5px;
  border: 1px solid var(--badge-variants-neutral-border, #dee2e6);
  background: #f1f3f5;
}

@media screen and (max-width: 1300px) {
  .container {
    width: 252px;
  }
}

@media screen and (max-width: 1190px) {
  .container {
    width: 314px;
  }
}

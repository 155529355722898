.progresschart-container {
    position: relative;
    height: 0;
    width: 100%;
    padding: 0;
    padding-bottom: 54%;
    //This has to match the aspect ratio to properly scale in IE11
}

.progresschart {
    overflow: visible;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}

// animations
.progresschart:not(.is-ie) {
    --points: 25;
    --line-animation-duration: 1s;
    --factor: calc(var(--line-animation-duration) / var(--points));
    $initial-delay: 1s;
    $line-delay: $initial-delay + .5s;
    animation: initial-delay-animation ($initial-delay + 0.5s) ease-out;
    .progresschart-mainline-fill {
        animation: progresschart-mainline-fill-animation 1s ease-in-out calc(#{$line-delay} + var(--line-animation-duration)) backwards;
    }
    .progresschart-mainline-line {
        animation: progresschart-mainline-line-animation var(--line-animation-duration) linear $line-delay backwards;
    }

    @for $i from 1 through 60 {
        .progresschart-pointlines path:nth-child(#{$i}) {
            animation: progresschart-pointlines-animation 0.1s ease-out calc(#{$line-delay} + (var(--factor) * #{$i - 1})) backwards;
            transform-origin: bottom;
        }
        .progresschart-points g:nth-child(#{$i}) {
            use:nth-child(1) {
                animation: progresschart-points-animation 0.1s cubic-bezier(.4,.15,.75,1.35) calc(#{$line-delay} + (var(--factor) * #{$i - 2})) backwards;
            }
            use:nth-child(2) {
                animation: progresschart-shield-animation 0.2s ease-out calc(#{$line-delay} + (var(--factor) * #{$i})) backwards;
            }
        }
    }

    @for $i from 1 through 4 {
        .progresschart-horizontals use:nth-child(#{$i}) {
            animation: progresschart-horizontals-animation 0.7s ease-out ($initial-delay + $i * 0.05s)  backwards;
        }
    }
    .progresschart-graphline  {
        animation: progresschart-graphline-animation 1s ease-out $initial-delay backwards;
    }
    .progresschart-text text {
        animation: progresschart-text-animation .2s ease-in-out $initial-delay backwards;
        &:nth-child(2) {
            animation-delay: ($initial-delay + .8s);
        }
    }
}


@keyframes progresschart-mainline-fill-animation {
    from {
        opacity: 0;
    }
}
@keyframes progresschart-mainline-line-animation {
    from {
        stroke-dasharray: 0 250;
    }
    to {
        stroke-dasharray: 250 250;
    }
}
@keyframes progresschart-pointlines-animation {
    from {
        transform: scaleY(.0);
    }
}
@keyframes progresschart-points-animation {
    from {
        transform: scale(.5);
        opacity: 0;
    }
}
@keyframes progresschart-shield-animation {
    from {
        transform: rotateY(90deg) translateY(3%);
        opacity: 0.5;
    }
}
@keyframes progresschart-horizontals-animation {
    from {
        transform: scaleX(0);
    }
}
@keyframes progresschart-graphline-animation {
    from {
        stroke-dasharray: 0 250;
    }
    to {
        stroke-dasharray: 250 250;
    }
}
@keyframes progresschart-text-animation {
    from {
        opacity: 0;
    }
}

@keyframes initial-delay-animation {
    from { opacity: 0; }
    to { opacity: 1; }
  }

.container {
  display: flex;
  flex-direction: column;
  gap: var(--sosafe-spacing-40);
  align-items: center;
}

.headerText {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  align-self: self-start;
}

.learning {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: var(--sosafe-spacing-8);
}

.moduleTag {
  margin-right: auto;
}

.modules {
  display: flex;
  flex-direction: column;
  gap: var(--sosafe-spacing-40);
}

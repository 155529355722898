.sosafe-DownloadCard {
    box-shadow: var(--sosafe-shadows-default);
    border-radius: .25rem;
    min-height: 380px;
    height: 100%;
    background: white;
    margin-top: 1rem;
}

.sosafe-DownloadCard.is-locked {
    color: var(--sosafe-color-gray--50);
}

.sosafe-DownloadCard-thumbnailContainer {
    background: var(--sosafe-color-coldWhite);
    padding: 1rem;
}

.sosafe-DownloadCard-thumbnail {
    box-shadow: var(--sosafe-shadows-default);
    height: 180px;
    width: calc(180px / 297 * 210);
}

.sosafe-DownloadCard-title {
    padding: 1rem 1rem 0;
    margin-bottom: .8em;
}

.sosafe-DownloadCard-description {
    padding: 0 1rem;
}

.sosafe-DownloadCard-content {
    display: grid;
	grid-gap: 0.5em;
    margin-top: auto;
    padding: 0 1rem 1rem;
}

.sosafe-DownloadCard-contentFilesize {
    color: var(--sosafe-color-gray--50);
}

.sosafe-DownloadCard-modalDownloadIcon {
    margin-right: .5rem;
}
.sosafe-DownloadCard-modal .sosafe-Modal-controls {
    float: none;
    justify-content: center;
}

.sosafe-DownloadCard-modal.sosafe-Modal {
    display: flex;
    flex-direction: column;
    min-width: 360px;
    width: 96vw;
	max-width: 800px;
	margin: 2vh 2vw;
	position: absolute;
	border-radius: .25rem;
	left: 50vw;
	top: 50vh;
	align-self: center;
	background-color: var(--sosafe-color-white);
	box-shadow: var(--sosafe-shadows-default);
	transform: translate(calc(-50% - 2vw), calc(-50% - 2vh));
	max-height: 96vh;
}

.sosafe-DownloadCard-modal .sosafe-Modal-title {
    margin: 1.25rem 1.25rem 0;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 1;
}

.sosafe-DownloadCard-modal .sosafe-Modal-icon {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 0.5rem;
}
.sosafe-DownloadCard-modal .sosafe-Modal-content {
    display: flex;
    flex-direction: column;
    margin: 1.25rem;
	text-align: start;
	min-height: 0;
}

.ReactModal__Overlay {
    z-index: 10000 !important;
}

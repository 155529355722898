.sosafe-PoliciesCard-wrapper {
    padding: 1.5rem;
    margin: auto;
    max-width: 100%;
}

.sosafe-PoliciesCard {
    padding: 2rem;
    max-width: 100%;
    width: 50rem;
    display: flex;
    justify-content: center;
    position: relative;
}

@media (max-width: 576px) {
    .sosafe-PoliciesCard-wrapper {
        padding: .5rem;
    }
    .sosafe-PoliciesCard {
        padding: 1rem;
    }
}

@import '../../scss/theme';

.rewardmodal-badge {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 300px;
  .rewardmodal-badge-image {
    position: relative;
    height: 240px;
    width: 240px;
  }
  .rewardmodal-badge-shine {
    position: absolute;
    width:100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: scale(1.2);
  }
  .rewardmodal-badge-particles {
    position: absolute;
    width:100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.rewardmodal-badge-shine:not(.is-ie) {
  animation-name: spin;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
      transform: scale(1.2) rotate(0deg);
  } to {
      transform: scale(1.2) rotate(360deg);
  }
}

.animate-scale:not(.is-ie) {
  transform: scale(0);
  animation: scale .1s linear forwards;
  animation-delay: .5s;
}

@keyframes scale {
  from {
      transform: scale(0);
  } to {
      transform: scale(1);
  }
}


.sosafe-DownloadsPage {
    padding: 0 1.5rem 1.5rem;
}

.sosafe-DownloadsPage-category .Grid > * {
	margin-top: 1.5rem;
}

.sosafe-DownloadsPage-category:not(:first-child):before {
    content: '';
    display: block;
    width: 100%;
    height: 2.5rem;
    border-bottom: 2px solid var(--sosafe-color-gray--50);
}
.sosafe-DownloadsPage-categoryTitle{ margin-top: 2.5rem; }
.sosafe-DownloadsPage-categorySubtitle{ margin-top: 1.25rem;}

@import '../../scss/theme';

.select-menu-container {
  position: absolute;
  top: 2.5rem;
  right: 0rem;
  background-color: white;
  z-index: 999;
  flex-direction: column;

  .select-menu-item {
    color: $secondary;
    padding: .15rem .75rem;
    cursor: pointer;

    &:hover {
      background-color: $gray-200;
    }
  }
}

.select-mobile-menu-container {
  position: fixed;
  top: 100%;
  left: 0;
  background-color: $white;
  z-index: 999;
  width: 100%;
  transform: translate(0, 10%);
  transition: transform .5s;
  flex-direction: column;
  padding: .5rem 1rem 1.5rem 1rem;

  &.open {
    transform: translate(0, -100%);
  }

  .select-scroll-container{
    max-height: 60vh;
    overflow-y: auto;
  }

  .select-menu-item {
    color: $secondary;
    padding: .25rem .5rem;
    cursor: pointer;

    &:hover {
      background-color: $gray-200;
    }
  }
}

.shake-container {
  position: relative;

  &.animated {
	-webkit-animation: wobble-ver-left 0.8s both;
	        animation: wobble-ver-left 0.8s both infinite;
  }
}

@-webkit-keyframes wobble-ver-left {
  0%,
  100% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-15px) rotate(-6deg);
            transform: translateY(-15px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateY(7px) rotate(6deg);
            transform: translateY(7px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateY(-7px) rotate(-3.6deg);
            transform: translateY(-7px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateY(4px) rotate(2.4deg);
            transform: translateY(4px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateY(-3px) rotate(-1.2deg);
            transform: translateY(-3px) rotate(-1.2deg);
  }
}
@keyframes wobble-ver-left {
    0%,
  100% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-15px) rotate(-6deg);
            transform: translateY(-15px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateY(7px) rotate(6deg);
            transform: translateY(7px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateY(-7px) rotate(-3.6deg);
            transform: translateY(-7px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateY(4px) rotate(2.4deg);
            transform: translateY(4px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateY(-3px) rotate(-1.2deg);
            transform: translateY(-3px) rotate(-1.2deg);
  }
}


.TourHint {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 2rem);
  min-width: 14rem;
  max-width: 190px;

  &.animate {
    animation: flow 1s ease-out alternate infinite;
  }
  &.isMobile {
    top: 4rem;
    left: auto;
    right: 2rem;
    transform: translate(0, 2rem);
    &::before {
      position: absolute;
      content: '';
      border-bottom: 1.2rem solid white;
      border-left: 1.2rem solid transparent;
      right: 0;
      top: -1rem;
    }
  }
  .hint-arrow {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
  }
}

.tour-hint-overlay {
  top: 73px !important;
  z-index: 100;
  background-color: rgba(black, .5);
}

@keyframes flow {
  from {
      transform: translate(-50%, 2rem);
  } to {
      transform: translate(-50%, 2.5rem);
  }
}

.overlay-tour-hint {
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 73px;
  background-color: rgba(black, .5);
}

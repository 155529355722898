@import "../../scss/theme";

.pill {
  padding: 5px 10px;
  border-radius: 12px; // icon-height + padding / 2
  display: flex;
  flex-direction: row;
  align-content: center;
  height: 24px;

  &.basic {
    color: $pill-orange;
    background-color: rgba($pill-orange, .1);
    .pill-icon {
      stroke: $pill-orange;
      fill: $pill-orange !important;
    }
    .pill-divider {
      border-right: 1px solid $pill-orange;
      margin: 0 .2rem;
    }
    &.two-lines .pill-divider {
      border-right: none;
      border-top: 1px solid $pill-orange;
      margin: .2rem 0;
    }
  }

  &.extension {
    color: $pill-blue;
    background-color: rgba($pill-blue, .1);
    .pill-icon {
      stroke: $pill-blue;
      fill: $pill-blue !important;
    }
    .pill-divider
    { border-right: 1px solid $pill-blue;
      margin: 0 .2rem;
    }
    &.two-lines .pill-divider {
      border-right: none;
      border-top: 1px solid $pill-blue;
      margin: .2rem 0;
    }
  }

  &.expert {
    color: $pill-purple;
    background-color: rgba($pill-purple, .1);
    .pill-icon {
      stroke: $pill-purple;
      fill: $pill-purple !important;
    }
    .pill-divider {
      border-right: 1px solid $pill-purple;
      margin: 0 .2rem;
    }
    &.two-lines .pill-divider {
      border-right: none;
      border-top: 1px solid $pill-purple;
      margin: .2rem 0;
    }
  }
}

@media 
(max-width: map-get($grid-breakpoints, sm)),
(min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)),
(min-width: map-get($grid-breakpoints, xl)) {
  .pill.two-lines {
    padding: 5px;
    flex-direction: column;
    align-content: start;
    border-radius: 4px;
  }
}

.container {
  width: 100%;
  padding: 0;
}

.group {
  width: 100%;
}

.grid {
  width: 100%;
}

.box1,
.box2,
.box3 {
  height: 100%;
  width: 419px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.box1 {
  gap: var(--sosafe-spacing-24);
}

.level {
  min-height: fit-content;
  display: flex;
  justify-content: center;
  align-self: center;
}

.experience {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

@media screen and (max-width: 1200px) {
  .box1,
  .box2,
  .box3 {
    width: 360px;
  }
}

@media screen and (max-width: 992px) {
  .secondSection {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .box1 {
    width: 357px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .box1,
  .box2,
  .box3 {
    flex-grow: 0;
  }
}

@media screen and (max-width: 480px) {
  .secondSection {
    display: flex;
    flex-direction: column;
    width: auto;
  }
}

@media screen and (max-width: 420px) {
  .box1,
  .box2,
  .box3 {
    width: 320px;
  }

  .secondSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 320px) {
  .box1,
  .box2,
  .box3 {
    width: 282px;
  }

  .secondSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  h1,
  p {
    text-align: center;
  }

}

.contentButton {
  display: flex;
  gap: 20px;
}

.primary {
  background-color: var(--sosafeThemed-color-primary, #05668e);
  border-color: var(--sosafeThemed-color-primary, #05668e);
}

.primary:hover {
  background-color: var(--sosafeThemed-color-primary--120, #045171);
  border-color: var(--sosafeThemed-color-primary--120, #045171);
}

.primary:focus {
  background-color: var(--sosafeThemed-color-primary--120, #045171);
  border-color: var(--sosafeThemed-color-primary--120, #045171);
  box-shadow: 0 0 0 0.2rem var(--sosafeThemed-color-primary--20, #cde0e8);
}

.secondary {
  background-color: var(--sosafeThemed-color-secondary, #02a896);
  border-color: var(--sosafeThemed-color-secondary, #02a896);
}

.secondary:hover {
  background-color: var(--sosafeThemed-color-secondary--120, #018678);
  border-color: var(--sosafeThemed-color-secondary--120, #018678);
}

.secondary:focus {
  background-color: var(--sosafeThemed-color-secondary--120, #018678);
  border-color: var(--sosafeThemed-color-secondary--120, #018678);
  box-shadow: 0 0 0 0.2rem var(--sosafeThemed-color-secondary--20, #ccedea);
}

.primaryOutline {
  color: var(--sosafeThemed-color-primary, #05668e);
  border-color: var(--sosafeThemed-color-primary, #05668e);
}

.primaryOutline:hover {
  color: var(--sosafeThemed-color-primary--120, #045171);
  border-color: var(--sosafeThemed-color-primary--120, #045171);
}

.primaryOutline:focus {
  color: var(--sosafeThemed-color-primary--120, #045171);
  border-color: var(--sosafeThemed-color-primary--120, #045171);
  box-shadow: 0 0 0 0.2rem var(--sosafeThemed-color-primary--20, #cde0e8);
}

.secondaryOutline {
  color: var(--sosafeThemed-color-secondary, #02a896);
  border-color: var(--sosafeThemed-color-secondary, #02a896);
}

.secondaryOutline:hover {
  color: white;
  border-color: var(--sosafeThemed-color-secondary--120, #018678);
  background-color: var(--sosafeThemed-color-secondary--120, #018678);
}

.secondaryOutline:focus {
  color: white;
  border-color: var(--sosafeThemed-color-secondary--120, #018678);
  background-color: var(--sosafeThemed-color-secondary--120, #018678);
  box-shadow: 0 0 0 0.2rem var(--sosafeThemed-color-secondary--20, #ccedea);
}

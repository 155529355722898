.activityCard {
  height: 1247px;
  overflow: auto;
}

.activities {
  background-color: var(--sosafe-color-white);
  width: 419px;
  height: 100%;
}

.title {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-bottom: var(--sosafe-spacing-24);
}

.title h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.title svg {
  width: var(--sosafe-spacing-16);
  height: var(--sosafe-spacing-16);
  align-self: center;
  color: #4589ff;
  cursor: pointer;
}

.activitiesBody {
  height: 100%;
}

.activitiesBody h3, .activitiesBody h4 {
  white-space: normal;
  overflow: hidden;
  hyphens: auto;
}

.loading {
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
}

.activityList {
  overflow-y: auto;
  overflow-x: visible !important;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 0;
  flex-basis: 0;
  margin: 0;
  padding: 10px;
}

.activityListBorder {
  position: relative;
  margin-left: 8px;
}

.activityList span {
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: var(--mantine-color-text, #000);
}

.activitiesLength {
  display: flex;
  margin-top: auto;
  margin-bottom: 10px;
}

.activityListBorder::before {
  content: "";
  display: block;
  height: 100%;
  width: 2px;
  position: absolute;
  left: -1px;
  background-color: #929292;
}

.activity {
  border-width: 2px !important;

  .border-top,
  .border-left,
  .border-right,
  .border-bottom {
    border-width: 2px !important;
  }
}

.activitiesDayDot {
  position: absolute;
  top: 0.52rem;
  left: 0;
  transform: translate(-50%, 0);
  color: #6fdc8c;
}

.activityDay {
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  position: relative;
}

@media screen and (max-width: 1200px) {
  .activities {
    width: 360px;
  }
}

@media screen and (max-width: 992px) {
  .activities {
    width: 547px;
  }
}

@media screen and (max-width: 480px) {
  .activities {
    width: 357px;
  }

  .activityCard {
    overflow: auto;
  }
}

@media screen and (max-width: 420px) {
  .activities {
    width: 320px;
  }

  .activityCard {
    height: 100%;
  }
}

@media screen and (max-width: 320px) {
  .activities {
    width: 282px;
  }

  .activityList {
    padding: 0;
  }

  .activityDay {
    padding: 0;
  }

  .activityListBorder::before {
    display: none;
  }

  .activitiesDayDot {
    display: none;
  }
}

/* For default font within survey to take Manrope */
@font-face {
  font-family: Manrope, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
    sans-serif;
}

.sd-container-modern {
  margin: auto;
}

/* For all questions titles */
.sd-title,
.sd-element__title span {
  font-size: var(--font-size-9);
  color: var(--sosafe-color-gray--100);
  font-weight: var(--font-weight-medium);
  line-height: 1.5;
  margin-bottom: var(--sosafe-spacing-8);
}

/* This overrides surveyjs global theme colors */
.sd-root-modern {
  --primary: var(--sosafe-color-primary--100);
  --background: var(--background-dim);
  --background-dim: var(--sosafe-color-white);
  --background-dim-light: var(--sosafe-color-white);
  --primary-foreground: var(--sosafe-color-white);
  --foreground: var(--sosafe-color-gray--100);
  --base-unit: var(--sosafe-spacing-8);
}

.sd-element__title .sv-string-viewer {
  font-size: 20px;
  line-height: var(--sosafe-spacing-24);
}

.sv-string-viewer {
  color: #5c5f66;
}

/* Never show action bar */
.sd-action-bar {
  display: none;
}

/* Preview page should not have question title blurred */
.sd-element__title.sd-element__title--disabled {
  opacity: 1;
}

.sd-title .sv-title-actions {
  display: flex;
  justify-content: center;
}

.sd-description {
  text-align: center;
  font-size: var(--sosafe-spacing-12);
  line-height: var(--sosafe-spacing-16);
  font-weight: 500;
}

/* Override width to be able to center align question */
.sd-question__header {
  width: auto;
}

/* Center align questions */
.sd-row__question {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Disable required star */
.sd-question__required-text {
  display: none;
}

/* Take out the border from question */
.svc-logic-question-value,
.sd-element--with-frame:not(.sd-element--collapsed) {
  box-shadow: none;
}

.sosafe-SurveyHub-Preview .sd-row.sd-clearfix.sd-page__row {
  border: 1px solid var(--sosafe-color-gray--20);
  border-radius: var(--sosafe-spacing-4);
}

/* Input items side by side */
.sd-selectbase {
  display: flex;
  gap: var(--sosafe-spacing-12);
}

.sd-title .sv-title-actions .sv-title-actions__title {
  text-align: center;
}

.sv_qstn_error {
  display: none !important;
}

.sd-element--with-frame:not(.sd-element--collapsed)
  > .sd-question__erbox--outside-question {
  display: none !important;
}

@media screen and (max-width: 450px) {
  .sd-title,
  .sd-element__title span {
    line-height: 12px !important;
  }

  .sd-title .sv-title-actions .sv-title-actions__title {
    padding: 0;
  }

  div [data-key] {
    min-width: 200px !important;
    max-width: 100%;
  }

  .svc-logic-question-value,
  .sd-element--with-frame:not(.sd-element--collapsed) {
    padding: 0;
  }

  .sd-title .sv-title-actions {
    padding: var(--sosafe-spacing-40) 0 0 0;
  }

  .sd-element__title .sv-string-viewer {
    font-size: 14px;
    font-weight: 600 !important;
    line-height: 20px;
  }

  .sd-title .sv-title-actions {
    width: 100%;
  }

  .sd-title .sv-title-actions .sv-title-actions__title {
    max-width: fit-content;
  }

  .sd-selectbase {
    padding-top: var(--sosafe-spacing-24);
    padding-bottom: var(--sosafe-spacing-12);
  }
}

@font-face {
  font-family: Manrope, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
    sans-serif;
}

.surveyCompleted {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.subHeading {
  color: var(--sosafe-color-gray--100);
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: var(--sosafe-spacing-16);
}

.text {
  color: var(--cool-gray-60);
  font-size: 14px;
  font-weight: 500;
  line-height: var(--sosafe-spacing-16);
  margin-bottom: var(--sosafe-spacing-16);
}

.loading {
  padding: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

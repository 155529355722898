.certmodal-badge {
  position: relative;
  max-width: 400px;
  padding-bottom: 100%;
  
  .certmodal-badge-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .certmodal-badge-shine {
    position: absolute;
    width:100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: scale(1.2);
  }
}

.certmodal-badge-shine:not(.is-ie) {
  animation-name: spin;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
      transform: scale(1.2) rotate(0deg);
  } to {
      transform: scale(1.2) rotate(360deg);
  }
}
@import '../../scss/theme';

.error-copy {
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.error-copy:hover {
  color: $primary;
}

.copy-icon {
  margin-right: .5rem;
}

.copy-text {
  text-decoration: underline;
}

.error-collapsible {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.collapsible-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: .5rem;
}

.collapsible-toggle:hover {
  color: $primary;
}

.collapsible-chevron {
  margin-right: .5rem;
  transition: transform .2s;
}

.open .collapsible-chevron {
  transform: rotate(90deg);
}

.collapsible-label {
  text-decoration: underline;
}

.collapsible-body {
  text-align: left;
  padding: .5rem;
  border: 1px solid $border-color;
  display: flex;

  pre {
    text-wrap: wrap;
  }

}

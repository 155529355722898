/**
* Classes to get branding colors into svgs
*
*/

.color-dark        { color: $dark; }
.color-icon-gray   { color: $icon-gray; }
.color-primary     { color: $primary; }
.color-secondary   { color: $secondary; }
.color-white       { color: $white; }
.color-pill-orange { color: $pill-orange; }
.color-pill-blue   { color: $pill-blue; }
.color-pill-purple { color: $pill-purple; }

.fill-dark              { fill: $dark; }
.fill-icon-gray         { fill: $icon-gray; }
.fill-light             { fill: $light; }
.fill-primary           { fill: $primary; }
.fill-secondary         { fill: $secondary; }
.fill-white             { fill: $white; }
.fill-black             { fill: $black; }
.fill-button-text       { fill: $button-text; }
.fill-none              { fill: none; }
.fill-pill-orange       { fill: $pill-orange; }
.fill-pill-blue         { fill: $pill-blue; }
.fill-pill-purple       { fill: $pill-purple; }
.fill-current           { fill: currentColor; }

.stop-color-primary   { stop-color: $primary; }
.stop-color-secondary { stop-color: $secondary; }

.stroke-button-text       { stroke: $button-text; }
.stroke-border            { stroke: $border; }
.stroke-dark              { stroke: $dark; }
.stroke-icon-gray         { stroke: $icon-gray; }
.stroke-light             { stroke: $light; }
.stroke-primary           { stroke: $primary; }
.stroke-secondary         { stroke: $secondary; }
.stroke-gray-100          { stroke: $gray-100; }
.stroke-gray-200          { stroke: $gray-200; }
.stroke-white             { stroke: $white; }
.stroke-black             { stroke: $black; }
.stroke-pill-orange       { stroke: $pill-orange; }
.stroke-pill-blue         { stroke: $pill-blue; }
.stroke-pill-purple       { stroke: $pill-purple; }
.stroke-current           { stroke: currentColor; }

.exitButton {
  margin-bottom: 24px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: start;
  align-self: center;
  width: 100%;
  max-width: 1120px;

  @media (max-width: 1200) {
    max-width: 1004px;
  }

  @media (max-width: 992px) {
    max-width: 896px;
  }

  @media (max-width: 320px) {
    max-width: 304px;
  }
}

.controller {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.footer {
  display: flex;
  justify-content: end;
  margin-top: 40px;
}

.nextButton {
  height: 40px;
}
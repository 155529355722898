.sophieContent {
    min-height: 304px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.sophieSVG {
    align-self: center;
    margin-bottom: -15px;
    z-index: 1;
}
.loading-query-screen_content {
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1
}

.experience {
  background-color: var(--sosafe-color-white);
  height: 100%;
}

.title {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-bottom: var(--sosafe-spacing-24);
}

.title svg {
  width: var(--sosafe-spacing-16);
  height: var(--sosafe-spacing-16);
  align-self: center;
  color: #4589ff;
  cursor: pointer;
}

.title h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.loader {
  height: 300px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.element {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.legend {
  display: flex;
  margin-top: 8px;
}

.bubble {
  display: flex;
  margin-top: 2px;
}

.legendList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.legendItem {
  display: flex;
  align-items: center;
  width: 80%;
  word-wrap: break-word;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  margin: 0 2px;
}

.legendItem svg {
  display: flex;
  align-items: center;
}

.marginLeftRight {
  margin-right: 8px;
}

@media screen and (max-width: 992px) {
  .experience {
    width: 357px;
  }
}

@media screen and (max-width: 420px) {
  .experience {
    width: 320px;
  }
}

@media screen and (max-width: 320px) {
  .experience {
    width: 282px;
  }
}

.animate-particle {
  opacity: 0;
  animation: fade 2s ease alternate infinite;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.delay-1 {
  animation-delay: .4s;
}

.delay-2 {
  animation-delay: .8s;
}

.delay-3 {
  animation-delay: 1.2s;
}

.delay-4 {
  animation-delay: 1.6s;
}

.delay-5 {
  animation-delay: 2s;
}

$base-duration: 1;

.duration-1 {
  animation-duration: $base-duration;
}

.duration-2 {
  animation-duration: $base-duration + .4s;
}

.duration-3 {
  animation-duration: $base-duration + .8s;
}

.duration-4 {
  animation-duration: $base-duration + 1.2s;
}



.arab {
  direction: rtl !important;
  text-align: right !important;

  input::placeholder {
    /* Firefox, Chrome, Opera */
    text-align: right;
  }

  input[type="email"]:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    text-align: center;
  }

  .direction-rtl {
    direction: rtl !important;
  }

  .direction-ltr {
    direction: ltr !important;
  }

  .input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  textarea, input:not(.btn) {
    text-align: right !important;
  }

  .rotate180 {
    transform: rotate(180deg);
  }

  .quickstart-label {
    right: 3rem;
    transform: translateX(47%) rotate(35deg);
  }

  .account-card,
  .card {

    .form-control[type="password"] {
        border-radius: 0.25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
        border-radius: 0.25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group-text {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
    }

    .form-control {
        border: 1px solid #ced4da;
    }

    .custom-form-control {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
    }

    .custom-form-control.valid {
        border: 1px solid var(--primary);
        border-left: 0;
    }

    .form-control-valid-icon {
        left: .5rem;
    }

    .validation-append {
        .input-group-text {
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0;
        }

        &.valid {
            .input-group-text {
                border: 1px solid var(--primary);
                border-right: 0;
            }
        }
    }

    .form-check {
        padding-right: 1.25rem;
        padding-left: 0;
    }

    .form-check-input {
        margin-right: -1.25rem;
        margin-left: 0;
        margin-top: 0.5rem;
    }
  }

  .swal2-footer.custom-footer > div {
    flex-direction: row-reverse;
  }

  .swal2-close {
    right: auto;
    left: 0;
  }

  .welcome-close {
    right: auto;
    left: 1rem;
  }

  .continue-btn {
    right: auto;
    left: 1.75rem;
  }

  @include media-breakpoint-down(sm) {

    .continue-btn {
      right: auto;
      left: 1.5rem;
    }
  }

  .card-ribbon-wrapper {
    left: auto;
	  padding-right: inherit;
    right: 0;
  }

  .card-ribbon-wrapper .card-ribbon {
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
  }

  .survey-form .field-radio-group .radio > label > span span {
    text-align: right;
  }

  .survey-form .field-radio-group .radio > label > span input[type=radio] {
    margin-right: 1px;
    margin-left: 0.5rem;
  }

  .survey-form .checkboxes .checkbox > label > span span {
    text-align: right;
  }

  .survey-form .checkboxes .checkbox > label > span input[type=checkbox] {
    margin-right: 1px;
    margin-left: 0.5rem;
  }

  .modal-close-x {
    left: 0.25rem;
    right: auto;
  }

  .rank-card .rank-card-compare-bar-indicator {
    &.upper {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 0;

      &.equal, &.upper-behind {
        border-bottom-left-radius: 0;
      }
    }

    &.lower {
      border-top-left-radius: 4px;
      border-top-right-radius: 0;

      &.equal, &.lower-behind {
        border-top-left-radius: 0;
      }
    }
  }

  .tour-mobile-tooltip {
    left: 45%;
  }
}


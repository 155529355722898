.badges {
  background-color: var(--sosafe-color-white);
  width: 419px;
  height: 100%;
}

.badgesBody {
  height: 100%;
}

.title {
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.title svg {
  width: var(--sosafe-spacing-16);
  height: var(--sosafe-spacing-16);
  align-self: center;
  color: #4589ff;
  cursor: pointer;
}

.subtitle {
  display: flex;
  flex-direction: row;
  gap: var(--sosafe-spacing-8);
  margin: 0;
  margin-bottom: var(--sosafe-spacing-24);
}

.subtitleNumbers {
  color: var(--mantine-color-gray-7);
}

.loading {
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
}

.badgesContent {
  display: flex;
  flex-direction: column;
  max-width: 490px;
  margin: auto 0;
}

@media screen and (max-width: 1200px) {
  .badges {
    width: 360px;
  }
}

@media screen and (max-width: 992px) {
  .badges {
    width: 547px;
  }
}

@media screen and (max-width: 480px) {
  .badges {
    width: 357px;
  }
}

@media screen and (max-width: 420px) {
  .badges {
    width: 320px;
  }
}

@media screen and (max-width: 320px) {
  .badges {
    width: 282px;
  }
}

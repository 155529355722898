@import "~@sosafe-platform-engineering/fe-lib-ui-react/dist/colors/colors.css";
@import "scss/_theme";

.displayNone {
  display: none;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.app {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.app-content {
  flex: 1 1 0%;
  display: flex;
  position: relative;
  min-height: 0;

  // background-image: url(shared/svgs/sosafe-logo-bg.svg);
  // background-repeat: no-repeat;
  // background-position: bottom left;
  // background-size: 50%;
}

.app.white-theme .app-content {
  background-color: var(--sosafe-color-white);
}

.app.gray-theme .app-content {
  background-color: var(--sosafe-color-gray--20);
}

.background-wave {
  position: fixed;
  left: 0;

  &.top {
    top: -4.5vw;
  }

  &.bottom {
    bottom: -3vw;
  }
}

.app-page {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.app-page-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar; // Fix IE registration bug
  -webkit-overflow-scrolling: touch;
  padding: 1rem 16px 0px 16px;
  position: relative;

  .app-page-content-scroll-container {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-self: center;
    max-width: 1920px;
    width: 100%;
  }
}

body.swal2-shown.swal2-height-auto {
  height: 100% !important;
}

.dev {
  position: fixed;
  left: 2rem;
  bottom: 3rem;
  border: 1px dashed red;
  padding: 1rem;
  color: lightgray;
}

/* 1 */
.ie9 img[src$=".svg"] {
  width: 100%;
}

/* 2 */
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%;
  }
}

.alert.alert-info {
  background-color: $salmon;
  border-color: $danger;
}

.backendBanner {
  position: relative;
  top: 0;
  padding: 4px 5px;
  width: 100%;
  z-index: 1000;
}

.switchBackendButton {
  border: 0 !important;
  transition: all 0.2s;
  width: 90px;
  height: 90px;

  &:hover {
    border: 0 !important;
    filter: brightness(1.1);
  }
}

.noSelect {
  user-select: none;
}

.localColor {
  background-color: #920086 !important;
}

.devColor {
  background-color: #ffc400 !important;
}

.stageColor {
  background-color: #3498db !important;
}

.prodColor {
  background-color: #e74c3c !important;
}

.backendFont {
  color: white;
  font-size: 2rem !important;
}

// custom aria outline
:focus,
.focus {
  outline: 0;
}

body.focus-visible {

  &:not(.btn,
    .sso-btn,
    .player-button,
    .form-control,
    .personalised_learning > button) {

    // keep the default style for these on focus
    &:focus,
    &.focus {
      box-shadow: 0 0 0.1rem 0.1rem rgba(0, 0, 0, 40%);
      outline: rgba(0, 0, 0, 20%) solid 0.1rem;
    }
  }
}

/* Temporary Class to show container limits */
.DummyCard {
  background-color: #343a3f;
  border-radius: 6px;
}

.DummyTab {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid #98fcb9;
  background: #c7fbd9;
}

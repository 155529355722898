.awarenessAssessmentPassed {
  width: 100%;

  @media screen and (min-width: 1200px) {
    min-width: 1004px;
  }

  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;

  h4 {
    color: #343a3f;
    text-align: center;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;

    /* 125% */
  }

  .thumbnailContainer {
    max-width: 350px;
    overflow: hidden;
    border-radius: 16px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.awarenessAssessmentFailed {
  width: 100%;

  @media screen and (min-width: 1200px) {
    min-width: 1004px;
  }

  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  .questionFeedbackContainer {
    display: flex;
    max-width: 400px;
    flex-direction: column;
    gap: 16px;
  }

  .questionFeedback {
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 16px;

    svg {
      width: 36px;
      height: 36px;
    }
  }

  .answerIcon {
    border-radius: 8px;
    min-width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rightAnswerIcon {
    background: #defbe6;
  }

  .wrongAnswerIcon {
    background: #ffd7d9;
  }

  .questionFeedbackText {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      font-family: Manrope;
      line-height: 150%;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
    }

    p {
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      line-height: 16px;
      color: #878d96;
      font-weight: 400;
    }
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 60px;
}


.canvasConfetti {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1000;
  background: transparent;
  width: 100%;
  height: 100%;
}

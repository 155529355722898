.container {
  max-width: 1304px;
  display: flex;
  justify-content: center;
  align-self: center;
  gap: var(--sosafe-spacing-40);
}

.widgets {
  display: flex;
  flex-direction: column;
  gap: var(--sosafe-spacing-32);
  width: 304px;
}

.levelCard {
  min-height: 480px;
}

.hintCard {
  min-height: 304px;
}

.trainingModules {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
}

.moduleHub {
  display: flex;
}

.mobileView {
  display: none;
}

@media (min-width: 800px) and (max-width: 1190px) {
  .container {
    align-self: stretch;
  }

  .widgets {
    width: 240px;
  }
}

@media screen and (max-width: 800px) {
  .container {
    display: flex;
    justify-content: center;
    gap: var(--sosafe-spacing-40);
  }

  .widgets,
  .trainingModules {
    display: none;
  }

  .mobileView {
    display: flex;
  }
}

@media screen and (max-width: 360px) {
  .container {
    max-width: 340px;
  }
}

@import "../scss/theme";

.auth-card-width {
  width: 100%;
  max-width: 700px;
}

.auth-card {
  overflow: hidden;
  padding: 0;

  #input {
    border-color: var(--mantine-color-gray-6);
  }

  .validation-append {
    .input-group-text {
      border-color: var(--mantine-color-gray-6);
    }
  }
}

.base-card-left {
  flex-basis: 45%;
  z-index: 0;
  background-color: var(--sosafe-color-secondary--20);

  & > img {
    object-position: left;
  }
}

.base-card-right {
  flex-basis: 55%;
  z-index: 1;
  padding: var(--sosafe-spacing-16) var(--sosafe-spacing-40)
    var(--sosafe-spacing-16) var(--sosafe-spacing-40);
}

.register-login-title-weight {
  font-weight: 500;
}

.cookies-policy {
  font-size: 12px;
  color: var(--mantine-color-gray-8);
  margin-top: var(--sosafe-spacing-10);
}

@import "../../scss/theme";

.card-ribbon-wrapper {
  position: absolute;
  left: 0;
  padding-left: inherit;
  bottom: 1rem;

  .card-ribbon {
    background-color: $light;
    border-top-right-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;

    &.passed,
    &.info {
      background-color: var(--sosafeThemed-color-primary);
    }

    &.soon-overdue {
      background-color: $warning;
    }

    &.overdue {
      background-color: $danger;
    }
  }
}

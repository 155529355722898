@import '../../../scss/theme';

.welcome-overlay {
  left: 0;
  top: 0;
  z-index: 1000;
  background-color: rgba(black, .5);
}

.welcome-tooltip {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.5rem 1.75rem;
  width: 800px;
  max-width: 95%;
  max-height: 95%;
  overflow: auto;

  &.hide {
    opacity: 0;
  }

  &.show {
    opacity: 1;
  }

  transition: opacity .5s;
}

.welcome-carousel {
  width: 100%;
}

.welcome-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.btn-container {
  width: 40px;
  position: absolute;
  z-index: 10;
  top: 172px;
  transform: translate(0, -50%);

  &.left {
    left: 1.5rem;
  }

  &.right {
    right: 1.5rem;
  }
}

.continue-btn {
  position: absolute;
  z-index: 10;
  bottom: 1.75rem;
  right: 1.75rem;
}

.background-img-welcome {
  background-position: 50%, 50% !important;
  background-repeat: no-repeat !important;
  background-size: contain;

  height: 355px;

  &.mobile-height {
    min-height: 180px;
  }
}

.text-min-height {
  min-height: 5rem; // prevent jumping
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: $light;
  cursor: pointer;

  &.dot-selected {
    background-color: $secondary;
  }
}

@include media-breakpoint-down(sm) {
  .text-min-height {
    min-height: 9rem; // prevent jumping
  }

  .background-img-welcome {
    height: 180px;
  }

  .btn-container {
    top: 100%;
    transform: translate(0, 25%);

    &.left {
      left: 0rem;
    }

    &.right {
      right: 0rem;
    }
  }

  .continue-btn {
    position: absolute;
    z-index: 10;
    bottom: 1.8rem;
    right: 1.5rem;
  }
}

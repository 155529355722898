@import '../../scss/theme';

.content-wrapper-v7 {
  width: 100%;
  height: 100%;

  .iframe-wrapper {
    background-color: rgb(0 0 0 / 10%);

    .player-iframe {
      width: 100dvw;
      height: 100dvh;
    }
  }

  &.content-wrapper-fullscreen .iframe-wrapper {
    padding-top: 100vh;
  }
}

iframe {
  border-width: 0;
  border-style: none;
  border-color: transparent;
  border-image: none;
}

.scorm-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: rgb(0 0 0 / 10%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 156px;
    height: 156px;
    padding: 10px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.close-button {
  position: absolute;
  top: 1.5rem;
  right: 2rem;
}

.close-button-icon {
  border: 1px solid var(--mantine-color-gray-4, #ced4da);
}

.close-button-v6 {
  position: absolute;
  top: 0.7rem;
  left: 2rem;
}

@media (max-width: 767px) {
  .content-wrapper-v7 {
    padding-left: 0;
    padding-right: 0;

    .active-presenter,
    .storyline,
    .active-presenter .iframe-wrapper,
    .storyline .iframe-wrapper {
      height: 100%;
    }
  }

  .close-button {
    top: 0.7rem;
    right: 0.5rem;
  }

  .close-button-icon {
    border: none;
  }

  .close-button-v6 {
    left: 0.5rem;
  }
}

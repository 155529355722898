@import '../../../scss/theme';

.content-wrapper {
  width: calc(100vh * 1.7 - 250px);
  max-width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  &.mobile-landscape {
    width: calc(100vh * 1.7);
  }

  &.version-five {
    width: calc(100vh * 1.4 - 250px);

    .iframe-wrapper {
      padding-top: calc(1082 / 1440 * 100%);
    }
  }

  .iframe-wrapper {
    height: 0;
    padding-top: calc(9 / 16 * 100%);
    position: relative;
    background-color: rgba(0, 0, 0, 0.1);

    .player-iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.version6 {
    width: 100%;
    height: 100%;
    padding: 0px;
    .iframe-wrapper {
      height: 100%;
    }
  }

  &.content-wrapper-fullscreen .iframe-wrapper {
    padding-top: 100vh;
  }
}

// buttons default and fullscreen
.buttons-wrapper {
  position: absolute;
  top: 1rem;
  left: 1rem;
  &.buttons-default {
    display: block;
  }
  &.buttons-mobile {
    display: none;
  }
}

// custom width per breakpoint - 1.5*2rem = 48px replaces the "max-padding-width" at this point active-presenters
// internal breakpoints are below 600px, so we move the padding until 600 and then switch to h-100 - works for scorm as well
@media (max-width: 648px) {
  .content-wrapper.active-presenter {
    width: 600px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 599px) {
  .content-wrapper {
    padding-left: 0;
    padding-right: 0;

    .active-presenter,
    .storyline,
    .active-presenter .iframe-wrapper,
    .storyline .iframe-wrapper {
      height: 100%;
    }
  }

  .content-wrapper:not(.content-wrapper-fullscreen) {
    .buttons-wrapper {
      &.buttons-default {
        display: none;
      }
      &.buttons-mobile {
        display: block;
      }
      .close-module-text {
        display: none;
      }
    }
  }
  .landscape-hint-wrapper {
    display: none;
  }
}
@media (min-width: 1500px) {
  .content-wrapper.version-five {
    width: calc(100vh * 1.3 - 250px);
  }
}

iframe {
  border-width: 0;
  border-style: none;
  border-color: transparent;
  border-image: none;
}

.player-button {
  position: relative;
  width: calc(0.5rem + 20px);
  height: calc(0.5rem + 20px);
  margin-bottom: 5px;
  background-color: $primary-scorm;
  border-color: $primary-scorm;

  &:hover,
  &:active,
  &:focus,
  &:visited,
  &:active:focus,
  &.active:focus {
    background-color: $primary-scorm !important;
    border-color: $primary-scorm !important;
    opacity: 0.9;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0.2rem 0.2rem rgba(0, 0, 0, 0.5);
  }

  svg {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.hide-desktop {
    top: 0.5rem;

    &.fullscreen {
      left: 2rem;
      margin-left: 0.5rem;
    }
    &.quit {
      left: 0.5rem;
    }
  }
}

.scorm-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 156px;
    height: 156px;
    padding: 10px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

/* Landscape hint */
.landscape-hint-wrapper {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -100%);
}

.landscape-phone {
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  width: 25px;
  border: 2px solid $primary;
  border-radius: 5px;
  animation: rotate 4s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  8% {
    transform: rotate(-90deg);
  }
  60% {
    transform: rotate(-90deg);
  }
  68% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@import '../../../scss/theme';

.app-footer {
    .pageFooter-logo {
        height: 30px;
        width: auto;
        filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.10));
    }

    .nav-item {
        white-space: nowrap;
    }

    .navbar-light .navbar-nav .nav-link {
        color: rgba(0, 0, 0, 0.55);

        &:hover {
            color: rgba(0, 0, 0, 0.75);
        }
    }

    .version-number {
        font-size: 80%;
        opacity: 0;
        transition: .2s;
        white-space: nowrap;

        &:hover {
            opacity: .8;
        }
    }
}


@include media-breakpoint-down(sm) {
    .app-footer {
        .pageFooter-logo {
            height: 25px;
        }

        a {
            line-height: 1.05;
        }
    }
}

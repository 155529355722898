.header {
  display: flex;
  gap: var(--sosafe-spacing-16);
  align-items: center;
}

.headerText {
  font-weight: 700;
  font-size: 36px;
  margin-bottom: var(--sosafe-spacing-24);
  margin-left: 18px;
}

.moduleTag {
  color: #da1e28;
  font-family: Manrope;
  font-size: var(--sosafe-spacing-12);
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  height: 22px;
}

.content {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  margin-bottom: var(--sosafe-spacing-24);
}

.modules {
  display: flex;
  justify-content: start;
  flex-direction: row;
  gap: 36px;
  padding-bottom: 24px;
}

@media screen and (max-width: 1440px) {
  .content {
    margin-top: 4px;
  }
}

@media screen and (max-width: 1200px) {
  .headerText {
    font-size: 32px;
    margin-left: 0;
  }
}

@media screen and (max-width: 992px) {
  .content {
    margin-top: 0;
  }

  .headerText {
    font-size: 24px;
    margin-left: 0;
  }
}

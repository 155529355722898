.sosafe-ranking-item__content {
  display: flex;
  align-items: center;
  line-height: 1em;
  padding: 5px 0;
  border-radius: 100px;
  font-family: Inter;
}

.sosafe-ranking-item__icon-container {
  left: 0;
  top: 5px;
  bottom: 0;
  width: calc(3 * var(--base-unit, 8px));
  flex-shrink: 0;
  align-items: self-start;

  svg:nth-of-type(1) {
    height: 16px;
    width: 10px;
  }

  svg:nth-of-type(2) {
    height: 24px;
    width: 10px;
  }
}

.sosafe-ranking-item__icon {
  visibility: hidden;
  top: 20px;
  fill: var(--primary, #19b394);
  position: absolute;
}

.sv-ranking--drag .sv-ranking-item:hover .sosafe-ranking-item__icon {
  visibility: hidden;
}

.sosafe-ranking-item--drag .sosafe-ranking-item__icon--hover {
  visibility: visible;
}

.sv-ranking--mobile .sosafe-ranking-item__icon--hover {
  visibility: visible;
  fill: #9f9f9f;
}

.sv-ranking--mobile.sv-ranking--drag .sosafe-ranking-item--ghost .sosafe-ranking-item__icon.sv-ranking-item__icon--hover {
  visibility: hidden;
}

.sv-ranking--mobile.sv-ranking-shortcut {
  max-width: 80%;
}

.sv-ranking--design-mode .sv-ranking-item:hover .sosafe-ranking-item__icon {
  visibility: hidden;
}

.sv-ranking--disabled {
  opacity: 0.8;
}

.sv-ranking-shortcut .sosafe-ranking-item__icon {
  fill: var(--primary, #19b394);
}


.sosafe-ranking-item--ghost {
  height: calcsize(6);

  .sv-ranking-item__text .sv-string-viewer {
    white-space: unset;
  }
}

.sosafe-ranking-item--ghost .sv-ranking-item__ghost {
  display: block;
}

.sosafe-ranking-item--ghost .sosafe-ranking-item__content {
  visibility: hidden;
}

.sosafe-ranking-item--drag .sosafe-ranking-item__content {
  box-shadow: 0 8px 16px rgb(0 0 0 / 10%);
  border-radius: 100px;
}

.sv-ranking {
  outline: none;
  user-select: none;
}

.sv-ranking-item {
  cursor: pointer;
  position: relative;
}


.sv-ranking-item__index {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background: var(--primary-light, rgb(25 179 148 / 10%));
  color: var(--foreground);
  border-radius: 100%;
  border: 2px solid transparent;
  width: 40px;
  height: 40px;
  line-height: 1em;

  svg {
    fill: var(--foreground);
    width: 16px;
    height: 16px;
  }
}

.sv-ranking-item__text {
  display: inline-block;
  overflow-wrap: break-word;
  word-break: break-all;
  margin: 0 calc(2 * var(--base-unit, 8px));

  .sv-string-viewer {
    overflow: initial;
    white-space: pre-line;
  }
}

.sv-ranking-item__ghost {
  display: none;
  background-color: var(--background-dim, #f3f3f3);
  border-radius: 100px;
  width: 200px;
  height: 55px;
  z-index: 1;
  position: absolute;
  left: 25px;
}

[dir="rtl"] .sv-ranking-item__ghost {
  left: initilal;
  right: 25px;
}

.survey-hub-sosafe-survey-hub-page {
  display: flex;
  gap: var(--sosafe-spacing-16);
  flex-direction: column;
  padding: 32px 16px 16px;
}

.survey-hub-sosafe-surveys-page div {
  width: auto !important;
}

.survey-hub-sosafe-surveys-question {
  display: flex;
  margin-top: var(--sosafe-spacing-32);
  flex-direction: column;
  padding: var(--sosafe-spacing-16);
  justify-content: center;
  align-items: center;
}

.survey-hub-sosafe-surveys-panel-title,
.survey-hub-sosafe-surveys-question-description {
  text-align: center;
}

.survey-hub-sosafe-surveys-question-header h5 {
  margin: 0;
  padding-bottom: var(--spacing-spacing-sm, 12px);
}

.survey-hub-sosafe-surveys-question-header {
  display: flex;
  padding: var(--sosafe-spacing-0, 0) var(--sosafe-spacing-11, 40px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.survey-hub-sosafe-surveys-panel-title span {
  color: var(--mantine-color-text, #000);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.1px;
}

.survey-hub-sosafe-surveys-question-description span {
  color: var(--mantine-color-gray-7, #495057);
  font-family: var(--font-family-inter, Inter);
  font-size: var(--font-size-xs, 12px);
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
}

.survey-hub-sosafe-surveys-question-radiogroup {
  display: flex;
  gap: var(--sosafe-spacing-16);
  flex-direction: row;
  justify-content: center;
}

.survey-hub-sosafe-surveys-question-content {
  width: 100%;
  padding-top: var(--sosafe-spacing-32, 32px);
}

@import "../../../scss/theme";

.badge-wrapper {
  height: 100px;
  width: 90px;
  transition: transform 0.3s;
  cursor: pointer;

  &:hover {
    transform: translate(0, -4px) scale(1.05);
  }
}

.badge-list-text-primary {
  color: var(--sosafeThemed-color-primary);
}

.badge-list-max-width {
  max-width: 490px;

  .border-top,
  .border-left,
  .border-right,
  .border-bottom {
    border-width: 2px !important;
  }
}

@import '../../../../../scss/theme';

.survey-form {

    .control-label {
        font-weight: bold;
        text-align: left;
        display: block;
    }

    .checkboxes {

        .checkbox {
            display: block;

            & > label {
                display: block;

                & > span {
                    display: flex;
                    align-items: flex-start;

                    span {
                        text-align: left;
                        flex: 1 1 0;
                    }

                    input[type=checkbox] {
                        margin-right: 0.5rem;
                        margin-top: 0.4rem;
                        margin-left: 1px;
                        margin-bottom: 1px;
                    }
                }
            }
        }
    }

    .field-radio-group {

        .radio {
            display: flex;

            & > label > span {
                display: flex;
                align-items: flex-start;

                span {
                    text-align: left;
                    flex: 1 1 0;
                }

                input[type=radio] {
                    margin-right: 0.5rem;
                    margin-top: 0.4rem;
                    margin-left: 1px;
                    margin-bottom: 1px;
                }
            }
        }
    }

    .error-detail {
        border: none;
        list-style-type: none;;
        padding: 0.5rem 0;
    }
}

@import '../../scss/theme';

.ModulePage {
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: auto;
    padding: 0 1rem;
}

.adjust-card-margin {
  margin: 0 -12px;
}

.select-module-page .desktop-btn {
  button {
    background-color: white;
    color: $dark;
    border: 1px solid $dark;
    min-width: 180px;
  }
  button:hover, button:active {
    background-color: white !important;
    color: $dark !important;
    border: 1px solid $dark !important;
  }
}

.select-module-page {
  .select-menu-item {
    color: $dark !important;
  }
  .select-menu-container {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 200px;
  }
}

@include media-breakpoint-down(sm) {
  .flex-column-sm {
    flex-direction: column;
  }
}
@include media-breakpoint-up(md) {
  .show-not-mobile {
    display: flex !important;
  }
}

@import '_theme.scss';

$breakpoints: $grid-breakpoints;
$gutter: $grid-gutter-width;
@each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);
    @include media-breakpoint-up($breakpoint, $breakpoints) {
        .d#{$infix}-grid {
            display: -ms-grid !important;
            display: grid !important;
            > * {
                padding-right: $gutter / 2;
                padding-left: $gutter / 2;
            }
        }

        .grid#{$infix}-col-1 {
            -ms-grid-columns: 1fr;
            grid-template-columns: 1fr;
            -ms-grid-rows: min-content 1fr; // IE11 and Firefox problem
            grid-template-rows: min-content 1fr;

        }
        .grid#{$infix}-col-1x2 {
            -ms-grid-columns: 1fr 2fr;
            grid-template-columns: 1fr 2fr;
            -ms-grid-rows: min-content 1fr; // IE11 and Firefox problem
            grid-template-rows: min-content 1fr;

        }
        .grid#{$infix}-col-3x1 {
            -ms-grid-columns: 3fr 1fr;
            grid-template-columns: 3fr 1fr;
            -ms-grid-rows: min-content 1fr; // IE11 and Firefox problem
            grid-template-rows: min-content 1fr;

        }
        .grid#{$infix}-col-4x1 {
            -ms-grid-columns: 4fr 1fr;
            grid-template-columns: 4fr 1fr;
            -ms-grid-rows: min-content 1fr; // IE11 and Firefox problem
            grid-template-rows: min-content 1fr;

        }
        
        .grid#{$infix}-row-1 {
            -ms-grid-row: 1;
            grid-row: 1;
        }
        .grid#{$infix}-row-2 {
            -ms-grid-row: 2;
            grid-row: 2;
        }
        .grid#{$infix}-row-3 {
            -ms-grid-row: 3;
            grid-row: 3;
        }
        
        .grid#{$infix}-col-1 {
            -ms-grid-column: 1;
            grid-column: 1;
        }
        .grid#{$infix}-col-2 {
            -ms-grid-column: 2;
            grid-column: 2;
        }
        
        
        .grid#{$infix}-row-span-1 {
            -ms-grid-row-span: 1;
            grid-row-end: span 1;
        }
        .grid#{$infix}-row-span-2 {
            -ms-grid-row-span: 2;
            grid-row-end: span 2;
        }
        
        .grid#{$infix}-column-span-1 {
            -ms-grid-column-span: 1;
            grid-column-end: span 1;
        }
        .grid#{$infix}-column-span-2 {
            -ms-grid-column-span: 2;
            grid-column-end: span 2;
        }
    }
}
    
.page {
  display: flex;
  width: 100%;
}

.container {
  min-height: 460px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;

  @media screen and (max-width: 520px) {
    padding: 40px 20px;
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: end;
}

.nextButton {
  height: 40px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  color: #697077;

  @media screen and (max-width: 520px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    gap: 8px;
  }
}

.progressbarContainer {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: flex-end;
  gap: 8px;

  @media screen and (max-width: 520px) {
    width: 100%;
  }
}

.progressbarContainer p {
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.progressbar {
  width: 200px;

  @media screen and (max-width: 520px) {
    width: 100%;
  }
}

.survey {  
  max-height: 544px;
  overflow-y: auto;
}

@media screen and (max-width: 520px) {
  .tag {
    align-self: stretch;
  }
  
  .tag svg {
    margin: 0;
  }
  
  .tag span {
    margin: 0;
  }
}


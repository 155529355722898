.surveyHubContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.surveyHubContent {
  display: flex;
  flex-direction: column;
  width: 700px;
  min-height: 468px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
  border-radius: var(--sosafe-spacing-16);
}

@media screen and (max-width: 1300px) {
  .surveyHubContent {
    max-width: 80vw;
    text-align: center;
  }
}

@media screen and (max-width: 450px) {
  .surveyHubContent {
    max-width: 90vw;
  }
}

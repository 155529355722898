@import '../../scss/theme';

.dated-module-card {
  min-height: 250px;
  ul {
    margin-bottom: 0;
  }
}

.large-size-btn {
  min-width: 140px;
}

.ul-no-padding-left ul {
  padding-left: 20px;
}

.svg-height {
  min-height: 160px;
}


@include media-breakpoint-down(sm) {
  .card-header-down-sm-height {
    height: 180px;
  }
}

.play-btn {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.locked-container {
  position: absolute;
    display: flex;
    justify-content: center;
    background-color: rgba(0,0,0,0.6);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
}

// bootstrap-like css-rules for background attributes

// background-color attributes
.background-color-white {background-color: white; }
.background-color-transparent {background-color: transparent; }

// background-attachment attributes
.background-attachment-scroll { background-attachment: scroll !important; }
.background-attachment-fixed { background-attachment: fixed !important; }
.background-attachment-local { background-attachment: local !important; }

// background-clip attributes
.background-clip-border-box { background-clip: border-box !important; }
.background-clip-padding-box { background-clip: padding-box !important; }
.background-clip-content-box { background-clip: content-box !important; }
.background-clip-text { background-clip: text !important; }

// background-origin attributes
.background-origin-border-box { background-origin: border-box !important; }
.background-origin-padding-box { background-origin: padding-box !important; }
.background-origin-content-box { background-origin: content-box !important; }

// background-position attributes
.background-position-top { background-position: top !important; }
.background-position-bottom { background-position: bottom !important; }
.background-position-left { background-position: left !important; }
.background-position-right { background-position: right !important; }
.background-position-center, .background-position-center-center { background-position: center !important; }

.background-position-top-left, .background-position-left-top { background-position: top left !important; }
.background-position-top-right, .background-position-right-top { background-position: top right !important; }
.background-position-bottom-left, .background-position-left-bottom { background-position: bottom left !important; }
.background-position-bottom-right, .background-position-right-bottom { background-position: bottom right !important; }
.background-position-center-right, .background-position-right-center { background-position: center right !important; }
.background-position-center-left, .background-position-left-center { background-position: center left !important; }

// background-repeat attributes
.background-repeat-repeat-x { background-repeat: repeat-x !important; }
.background-repeat-repeat-y { background-repeat: repeat-y !important; }
.background-repeat-repeat { background-repeat: repeat !important; }
.background-repeat-space { background-repeat: space !important; }
.background-repeat-round { background-repeat: round !important; }
.background-repeat-no-repeat { background-repeat: no-repeat !important; }

// background-size attributes
.background-size-cover { background-size: cover !important; }
.background-size-contain { background-size: contain !important; }

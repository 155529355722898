.sosafe-PoliciesPage-loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sosafe-PoliciesPage {
    text-align: center;
    padding: 0 1rem 1rem;
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sosafe-PoliciesPage-title {
    margin-top: 2.5rem;
}

.sosafe-PoliciesPage-subtitle {
    font-weight: normal;
    font-size: var(--sosafe-typo-h4);
    margin: 1rem 0;
}

.sosafe-PoliciesPage-buttons {
    display: flex;
    margin-top: 1.25rem;
}

.sosafe-PoliciesPage-button {
    margin: 0 .5rem;
}

@media (max-width: 576px) {
    .sosafe-PoliciesPage {
        padding: 0 .5rem .5rem;
    }
    
    
    .sosafe-PoliciesPage-title {
        margin-top: 1rem;
    }
    
    .sosafe-PoliciesPage-subtitle {
        margin: .5rem 0;
    }
    
    .sosafe-PoliciesPage-buttons {
        margin-top: .75rem;
    }
    
    .sosafe-PoliciesPage-button {
        margin: 0 .25rem;
    }
}
.sosafe-PoliciesPage-stepper{
    margin-bottom: 1rem;
}
.sosafe-PoliciesPage-stepper.is-hidden{
    display: none;
    margin-bottom: 0;
}

.sosafe-PolicyView-checkbox {
    text-align: start;
}
@import "../../scss/theme";

/* Custom swal classes */
.custom-close-button,
.swal2-close {
  color: var(--mantine-color-gray-7);
  &:hover {
    color: var(--mantine-color-black);
  }
}

.custom-footer {
  border-top: 1px solid $border;
  margin-top: 20px;
}

.custom-icon {
  margin-bottom: 16px;
}

.custom-font {
  color: var(--mantine-color-gray-7);
}

.swal2-popup.swal2-toast {
  .swal2-title {
    white-space: nowrap;
  }
}

/*
  Alert
 */

body.swal2-toast-shown .swal2-container.sosafe-ModalAlert-alertContainer,
body.swal2-toast-shown .swal2-container.sosafe-ModalAlert-alertContainerCallback {
  top: 75px;
}
.sosafe-ModalAlert-alertContainer .swal2-popup.swal2-toast,
.sosafe-ModalAlert-alertContainerCallback .swal2-popup.swal2-toast {
  padding: .5rem;
  flex-direction: row;
}

.sosafe-ModalAlert-alertContainerCallback .swal2-popup .swal2-actions {
  margin: 0;
}

.sosafe-ModalAlert-alertContainer .swal2-popup.swal2-toast .swal2-title,
.sosafe-ModalAlert-alertContainerCallback .swal2-popup.swal2-toast .swal2-title {
	margin: 0.6em 1em;
	line-height: 1.5;
}


/* Success */
.success-alert-border {
  border: 2px solid $success;
}

.swal2-popup.swal2-toast {
  box-shadow: 0 0 0.625em $success;
}

.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: $success;
}

.swal2-icon.swal2-success .swal2-success-ring {
  border: 0.25em solid rgba($success, 0.3);
}

/* Error */
.error-alert-border {
  border: 2px solid $danger;
}

/* Warning */
.warning-alert-border {
  border: 2px solid $warning;
}

/* info */
.info-alert-border {
  border: 2px solid $info;
}

/* question */
.question-alert-border {
  border: 2px solid $question;
}

/*
  Content
 */

/* Text */
.swal2-content p {
  text-align: center;
}
/* List */
.swal2-content ul {
  text-align: left;
  border: 1px solid $success;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.swal2-styled.swal2-cancel {
  background-color: $danger !important;
}

.swal2-styled.swal2-confirm {
  background-color: $success !important;
}

/*
    Checkbox
*/
/* The container */
.cb-container--modal {
  display: block;
  position: relative;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  width: 100%;
  height: 10px;
  margin-left: auto;
  margin-right: auto;
}

.cb-container--modal p {
  position: absolute;
  top: 50%;
  left: 1.3em;
  transform: translate(0, -50%);
}

/* Create a custom checkbox */
.checkmark-checkbox--modal {
  width: 1em;
  height: 1em;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $light;
  border: 0px solid $dark;
  border-radius: 0.25rem;
  transform: translate(0, -50%);
  background-color: $success;
}

/* On mouse-over, add a background color */
.cb-container--modal:hover .checkmark-checkbox--modal {
  background-color: $success;
}

/* When the checkbox is checked, add a background */
.cb-container--modal .checked {
  background-color: $success;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-checkbox--modal:after {
  content: "";
  display: none;
  position: absolute;
}

/* Show the checkmark when checked */
.cb-container--modal .checked:after {
  display: block;
}

/* Style the checkmark/indicator */
.cb-container--modal .checkmark-checkbox--modal:after {
  overflow: auto;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 4px;
  right: 0;
  border: solid $white;
  border-width: 0 2px 2px 0;
  width: 0.4em;
  height: 0.8em;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

body.swal2-shown.swal2-height-auto {
  height: 100% !important;
}

.modal-progress {
  position: absolute;
  right: 1rem;
  bottom: 0.5rem;
}

.modal-close-x {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  color: $dark !important;
}

.swal2-validation-message {
  margin-top: 1rem;
}

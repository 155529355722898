@import '../../scss/theme';

.activity-wrapper {
  flex-basis: 70vh;
  min-height: 500px;
  align-self: stretch;
  flex-grow: 1;
}

.activity-list {
  overflow-y: auto;
  overflow-x: visible !important;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 0;
  flex-basis: 0;
}

.activity-list-border {
  position: relative;
}

.activity-list-border::before {
  content: '';
  display: block;
  height: 100%;
  width: 2px;
  position: absolute;
  left: -1px;
  background-color: #929292;
}

.activity {
  border-width: 2px !important;

  .border-top, .border-left, .border-right, .border-bottom {
    border-width: 2px !important;
  }
}

.activity-day-dot {
  position: absolute;
  top: .52rem;
  left: 0;
  transform: translate(-50%, 0);
}

.activity-badge {
  position: relative;
  flex: 0 0 100px;
  height: 100px;
  margin-right: 1.5rem;

  .activity-badge-image {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .activity-badge-shine {
    position: absolute;
    width:100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: scale(1.2);
  }
}

.activity-badge-shine:not(.is-ie) {
  animation-name: spin;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
      transform: scale(1.2) rotate(0deg);
  }

 to {
      transform: scale(1.2) rotate(360deg);
  }
}

@media screen and (max-width: 420px) {
  .activity-badge {
    margin: 15px 0
  }
}
.container {
  width: 100%;
  max-width: 980px;
  height: auto;
  z-index: 1;
}

.slide {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 40px;
  height: 40px;
  color: var(--button-primary-active-color-background);
}

.mantineRoot > .mantineControls {
  position: absolute;
  top: 45%;
  margin: 0 15px;
}

.mantineRoot > .mantineControls > .mantineControl {
  opacity: 1;
}

.mantineIndicators {
  bottom: -30px;
}

.mantineIndicators button {
  background-color: var(--sosafe-color-primary--100);
  width: 10px;
  height: 8px;
  transition: width 250ms ease;

  &[data-active] {
    width: 20px;
  }
}

@media screen and (max-width: 1300px) {
  .container {
    width: 830px;
  }
}

@media (min-width: 800px) and (max-width: 992px) {
  .container {
    width: 480px;
  }
}

@media (min-width: 992px) and (max-width: 1190px) {
  .container {
    width: 648px;
  }
}

@media screen and (max-width: 1190px) {
  .mantineRoot > .mantineControls {
    margin: -4px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 192px;
  border-radius: 16px;
}

.contentSpinner {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 100px;
  border-radius: 16px;
}

.factContent {
  min-height: 75px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.spinner {
  align-self: center;
}

.title {
  margin-bottom: var(--sosafe-spacing-16);
}

.icon {
  display: flex;
  justify-content: center;
}

.scroll {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.fontCount {
  display: flex;
  align-self: center;
  font-size: 12px;
}

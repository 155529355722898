.Notification-system.reward-modal-close.swal2-close {
  margin-bottom: -0.5em;
}

.swal2-title {
  font-size: var(--sosafe-typo-h2);
  text-align: center;
  margin-top: 0.7em;
  margin-bottom: 1em;
}

.Notification-system.reward-modal-title {
  /* visibility: hidden; */
}

.badge-svg {
  width: 60px;
  height: 75px;
  margin: 0 0.125rem;
  transition: transform .3s;
  cursor: pointer;

  &:hover {
    transform: translate(0, -4px) scale(1.05) ;
  }
}

@import '../../scss/theme';

.AccountPage {
    height: 100%;
    width: 100%;
    margin: 0 auto auto;
    padding: 0 1rem;
}

.certificate-warning-text{
    color: var(--mantine-color-error, #000);
    display: flex;
    margin: 0.25rem;
    font-size: small;
}

/* animation: [name](=none) | [duration](=0s) | [timing-function](=ease) | [delay](=0s) | [iteration-count](=1) | [direction](=normal) | [fill-mode](=none) | [play-state](=running) */

.spiderchart:not(.is-ie) {
  $initial-delay: 1s;

  animation: initial-delay-animation 0.3s ease-out ($initial-delay - 0.2s) backwards;

  .spiderchart-scales circle {
    animation: spiderchart-scales-animation 1.3s ease-in-out $initial-delay backwards;
    transform-origin: center center;
  }

  .spiderchart-progress {
    animation: spiderchart-progress-animation 1s ease-in-out ($initial-delay + 0.5s) backwards;
    transform-origin: center center;
    fill: #00A482;
    opacity: 1;
  }

  .spiderchart-axes line {
    animation: spiderchart-axes-animation 0.3s ease-in-out backwards;
    transform-origin: center center;
    &:nth-child(1) { animation-delay: $initial-delay - 0.2s; }
    &:nth-child(2) { animation-delay: $initial-delay + 0.0s; }
    &:nth-child(3) { animation-delay: $initial-delay + 0.2s; }
    &:nth-child(4) { animation-delay: $initial-delay + 0.4s; }
    &:nth-child(5) { animation-delay: $initial-delay + 0.6s; }
    &:nth-child(6) { animation-delay: $initial-delay + 0.8s; }
  }

  .spiderchart-textdots > g, .spiderchart-textdots > text {
    animation: spiderchart-textdots-animation 0.2s backwards;
    &:nth-child(1) { animation-delay: $initial-delay + 0.1s; }
    &:nth-child(2) { animation-delay: $initial-delay + 0.3s; }
    &:nth-child(3) { animation-delay: $initial-delay + 0.5s; }
    &:nth-child(4) { animation-delay: $initial-delay + 0.7s; }
    &:nth-child(5) { animation-delay: $initial-delay + 0.9s; }
    &:nth-child(6) { animation-delay: $initial-delay + 1.2s; }
  }

}

@keyframes spiderchart-scales-animation {
  from {
    transform: rotate(-90deg);
    stroke-dasharray: 0 200;
  }

  to {
    stroke-dasharray: 200 200;
    transform: rotate(-90deg);
  }
}

@keyframes spiderchart-textdots-animation {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes spiderchart-axes-animation {
  from { transform: scale(0); }
}

@keyframes spiderchart-progress-animation {
  from { transform: scale(0); }
}

@keyframes initial-delay-animation {
  from { opacity: 0; }
  to { opacity: 1; }
}
.badge {
  all: unset; /* Remove button default style */
  min-width: 62px;
}

.badge img {
  width: 100%;
  height: auto;
}



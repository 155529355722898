.badgeModal {
  max-width: 400px;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 8px;
  margin-top: 30px;
}

.content img {
  max-width: 240px;
  min-height: 240px;
  height: auto;
  z-index: 1;
}

.closeBtn {
  width: 100%;
}

.description {
  width: 100%;
  word-wrap: break-word;
}

@keyframes rotate {
  from {
    transform: rotate(120deg) scale(3);
  }

  to {
    transform: rotate(0deg) scale(3);
  }
}

.shineWrapper {
  position: absolute;
  top: 30%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.shine {
  transform-origin: center;
  animation: rotate 5s ease-out;
  transform: scale(3);
}

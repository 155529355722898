.cakechart:not(.is-ie) {
  $initial-delay: 1s;
  animation: initial-delay-animation ($initial-delay + 0.5s) ease-out;
  .cakechart-indicator-animation {
    animation: animateCakeChartIndicator 1.2s ease-out $initial-delay both;
  }
  
  .cakechart-dots-animation {
    animation: animateCakeChartDotsLabels .5s both;
    &:nth-child(1) { animation-delay: $initial-delay + 1.1s; }
    &:nth-child(2) { animation-delay: $initial-delay + 1.25s; }
    &:nth-child(3) { animation-delay: $initial-delay + 1.40s; }
    &:nth-child(4) { animation-delay: $initial-delay + 1.55s; }
    &:nth-child(5) { animation-delay: $initial-delay + 1.7; }
  }
    
  .cakechart-labels-animation {
    animation: animateCakeChartDotsLabels .5s ease-in-out ($initial-delay + 1.7s) both;
  }
}


@keyframes animateCakeChartIndicator {
  from {
    stroke-dasharray: 0 1000;
    opacity: 0.4;
  }
}

@keyframes animateCakeChartDotsLabels {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes initial-delay-animation {
  from { opacity: 0; }
  to { opacity: 1; }
}
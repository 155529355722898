@import "../../scss/theme";

.quick-start-card {
  min-height: 145px;
  height: 100%;
  display: flex;
  flex: 1 1 auto;

  .image-section {

    .image {
      height: 100%;
      width: 100%;
      background-position: 0, 50% !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
    }
  }
}

.quickstart-label {
  position: absolute;
  top: 1.5rem;
  left: 3rem;
  transform: translateX(-47%) rotateZ(-35deg);
  padding: .5rem;
  background: rgba(white, .95);
  width: 15rem;
}

@import "../../scss/theme";

.badgemodal-badge {
  position: relative;
  height: 300px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  .badgemodal-badge-image {
    position: relative;
    height: 240px;
    width: 240px;
  }
  .badgemodal-badge-shine {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: scale(1.2);
  }
}

.badge-modal ul {
  border: none;
}

.badge-modal-popup {
  width: 25rem;
}

.swal2-close,
.badge-modal-close {
  color: var(--mantine-color-gray-7);
  &:hover {
    color: var(--mantine-color-black);
  }
}

.animate-scale:not(.is-ie) {
  transform: scale(0);
  animation: scale 0.1s linear forwards;
  animation-delay: 0.1s;
}
@keyframes scale {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.badgemodal-badge-shine:not(.is-ie) {
  animation-name: spin;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: scale(1.2) rotate(0deg);
  }
  to {
    transform: scale(1.2) rotate(360deg);
  }
}

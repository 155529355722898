.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-help {
  cursor: help;
}

.background-image {
  background-position: 50%, 50% !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.image-error {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: auto;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 1 !important;
}

.animate-opacity {
  transition: opacity 0.8s;
}

.pulse-loader {
  & > div {
    & > div {
      background-color: $secondary;
    }
  }
}

.min-w-180 {
  min-width: 180px;
}

.hover-shadow {
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;
  }
}

.shadow-top {
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.2);
}

.svg-absolute-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 101%; // prevent border
}

.dark-screen-overlay {
  opacity: 0;
  background-color: $secondary;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  visibility: hidden;
  transition:
    opacity 0.3s,
    visibility 0s 0.5s;

  &.open {
    opacity: 0.3;
    visibility: visible;
    transition: opacity 0.3s;
  }
}

.svg-grayscale {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}

.text-pill-orange {
  color: $pill-orange;
}
.text-pill-blue {
  color: $pill-blue;
}
.text-pill-purple {
  color: $pill-purple;
}

.text-footer-dark {
  color: $footer-dark !important;
}

.z-index-1 {
  z-index: 1;
}

.text-underlined {
  text-decoration: underline !important;
}

.modal-shine {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  height: 350px;
  width: 350px;
  z-index: -1;

  &:not(.is-ie) {
    animation-name: spin;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  &.large {
    height: 600px;
    width: 600px;
  }
}

@keyframes spin {
  from {
    transform: translate(-50%, -55%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -55%) rotate(360deg);
  }
}

@mixin flex-basis-x($steps) {
  @for $i from 0 through $steps {
    .flex-basis-#{$i} {
      flex-basis: $i * 1%;
    }
  }
}

@include flex-basis-x(100);

.max-width-300px {
  max-width: 300px;
  white-space: normal;
}

.flipx {
  transform: scaleX(-1);
}

.sosafe-InvisibleHeading {
  opacity: 0;
  pointer-events: none;
}

.actionBtn {
  display: flex;
  justify-content: end;
  align-items: end;
  gap: 12px;
}

.refreshText {
  padding-left: 8px;
}

.buttonRefresh {
  padding: 0 12px;
}

@media screen and (max-width: 393px) {
  .tag {
    display: none;
  }
}

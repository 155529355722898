.surveyContainer {
  display: flex;
  flex-direction: column;

  /* 639 - 36 - 32 - 40 */
  height: 100%;
  background-color: var(--sosafe-color-white);
  border-radius: var(--sosafe-spacing-16);
}

.surveyProgress {
  width: 120px;
}

.surveyProgress_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--sosafe-spacing-8);
}

.surveyProgress__disabled {
  padding-top: 16px;
  visibility: hidden;
}

.surveyTitle {
  font-size: var(--sosafe-typo-h2);
  font-weight: 700;
  font-family: Roboto;
  line-height: var(--sosafe-spacing-32);
}

.surveyContainer_content {
  max-height: 334px;
  flex-grow: 2;
  padding: var(--sosafe-spacing-40);
  padding-bottom: 0;
  overflow-y: auto;
}

/* Needed to have the survey scrollable in the preview screen */
.surveyJSWrapper {
  min-width: 200px;
  width: 100%;
  overflow-y: auto;
  scrollbar-color: var(--sosafe-color-primary--100) rgb(229 229 229);
  scrollbar-width: thin;
}

.bodyAnimatedUp {
  opacity: 0;
  transform: translateY(-10%);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.animationFinished {
  opacity: 1;
  transform: translateY(0%);
}

.loading {
  height: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.surveyJSWrapper::-webkit-scrollbar {
  width: 6px;
}

.surveyJSWrapper::-webkit-scrollbar-track {
  border-radius: 5px;
}

.surveyJSWrapper::-webkit-scrollbar-thumb {
  background-color: var(--sosafe-color-gray--50);
  border-radius: 3px;
}

.surveyContainer_content .actionBar {
  background-color: var(--sosafe-color-primary--100);
  border: 1px solid var(--sosafe-color-primary--100);
  color: var(--button-primary-color-text, #fff);
  font-weight: 500;
}

.actionBar {
  display: flex;
  justify-content: flex-end;
  gap: var(--sosafe-spacing-8);
  border-top: 1px solid var(--sosafe-color-gray--10);
  margin-top: auto;
  padding: var(--sosafe-spacing-16);
}

@media screen and (max-width: 450px) {
  .surveyContainer_content {
    display: flex;
    flex-direction: column;
    padding: 12px 18px;
  }

  .surveyProgress {
    flex-grow: 2;
  }

  .bothButtons {
    justify-content: space-between;
  }
}

.levelCard {
  background-color: var(--sosafe-color-white);
  height: 100%;
  width: 419px;
}

.levelCardBody {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.levelBadge {
  height: 100%;
}

.levelBadge img {
  width: 117px;
  height: 142px;
}

.info {
  width: 240px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.info p {
  font-size: 14px;
}

.bodyElement {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-items: center;
  align-items: center;
}

.certificateButton {
  align-self: stretch;
}

.progressBar {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--sosafe-spacing-8);
}

.progressBar svg {
  width: var(--sosafe-spacing-16);
  height: var(--sosafe-spacing-16);
  align-self: center;
  color: #4589ff;
  cursor: pointer;
}

.progressBarHeader {
  display: flex;
  justify-content: space-between;
  justify-items: center;
}

.progreeBarLevelXp {
  color: var(--cool-gray-60);
  font-weight: 500;
}

.progreeBarLevelXp span {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.progreeBarLevelXp p {
  margin: 0;
}

@media screen and (max-width: 1200px) {
  .levelCard {
    width: 360px;
  }
}

@media screen and (max-width: 992px) {
  .levelCard {
    width: 357px;
  }
}

@media screen and (max-width: 420px) {
  .levelCard {
    width: 320px;
  }
}

@media screen and (max-width: 320px) {
  .levelCard {
    width: 282px;
  }

  .progressBar {
    width: 123px;
  }

  .progreeBarLevelXp {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .warning {
    display: none;
  }

  .levelBadge img {
    width: 81.33px;
    height: 98px;
  }
}

@import "../../scss/theme";

.category-page {
  .level-card {
    .custom-hr {
      border-top: 2px solid $border-color;
    }
  }

  .neg-margin-bottom {
    margin-bottom: -0.5rem;
  }

  .level-shield {
    width: 10rem;
    height: 11.8rem;
  }

  @include media-breakpoint-down(sm) {
    .level-shield {
      width: 6rem;
      height: 7rem;
    }
  }
}

.certificate-wrapper > div {
  display: flex;
}

.certificate-wrapper:not(:has(div)) {
  display: flex;
}

.card a,
.card a:hover {
  width: 100%;
  color: #494949;
  text-decoration: inherit;
}

.card {
  transition: 0.35;
  padding: 20px 32px;
}

.card:hover {
  cursor: pointer;
  box-shadow: var(--sosafe-shadow-10);
}

.body {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.bodyElement {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-items: center;
  align-items: center;
}

.bodyElement small {
  align-self: flex-end;
  color: var(--sosafe-90);
}

.certificateButton {
  align-self: stretch;
}

.levelBadge {
  width: 127px;
  height: 157.5px;
  margin-top: var(--sosafe-spacing-32);
}

.levelBadge img {
  width: 126px;
  height: auto;
}

.progressBarContainer {
  display: flex;
  flex-direction: column;
  gap: var(--sosafe-spacing-8);
}

.progressBar {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.progressBar svg {
  width: var(--sosafe-spacing-16);
  height: var(--sosafe-spacing-16);
  color: #4589ff;
}

.progressBarHeader {
  display: flex;
  justify-content: space-between;
  justify-items: center;
}

.progressBarHeader p {
  align-self: center;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.levelName {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.progreeBarLevelXp>strong {
  font-weight: 700;
  color: #4d5358;
}

.progreeBarLevelXp {
  font-size: 14px;
  line-height: 20px;
}

.progreeBarLevelXp>span {
  font-weight: 500;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  color: #697077;
}

.progreeBarLevelXp p {
  margin: 0;
}

.badges {
  display: flex;
}

.learnMore {
  all: unset;
  align-self: self-end;
  font-weight: 500;
  font-size: 12px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.certificateImage {
  width: 320px;
  height: auto
}

@keyframes rotate {
  from {
    transform: rotate(120deg) scale(3);
  }

  to {
    transform: rotate(0deg) scale(3);
  }
}

.shineWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  height: 100%;

  /* width: max-content; */
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: -1;
}

.shine {
  transform-origin: center;
  animation: rotate 5s ease-out;
  transform: scale(3);
}

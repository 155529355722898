.welcomeCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-7, 16px);
  align-self: stretch;
  padding: 20px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid var(--color-default-border, #ced4da);

  /* replace color with secondary once we have branding working */
  background: var(--sosafe-color-secondary--100);
  color: var(--sosafeThemed-color-secondary--140, #fff);
}

.heading {
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40.7px; /* 169.583% */
  letter-spacing: -0.06px;
  align-self: stretch;
}

@media screen and (max-width: 1190px) {
  .subHeading,
  .description {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .welcomeCard {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: flex-start;
    width: 100%;
    max-width: 448px;
    padding: 20px;
    min-height: 150px;
  }

  .heading {
    font-size: 20px;
    font-weight: 700;
    line-height: 27.32px;
  }

  .subHeading {
    font-size: 16px;
    line-height: var(--sosafe-spacing-24);
  }
}

@media screen and (max-width: 440px) {
  .background {
    bottom: 0;
    left: 15%;
  }

  .welcomeCard {
    width: 100%;
    max-width: 448px;
  }
}

@media screen and (max-width: 393px) {
  .background {
    bottom: 0;
    left: 5%;
  }

  .welcomeCard {
    width: 100%;
    max-width: 390px;
  }
}

@media screen and (max-width: 333px) {
  .background {
    bottom: 0;
    left: -10%;
  }

  .welcomeCard {
    width: 100%;
    max-width: 288px;
  }
}

@import './_theme.scss';

.activity-list {
  scrollbar-width: thin;
  scrollbar-color: $primary $border;
}

.activity-list::-webkit-scrollbar {
    width: 7px;
    height: 4px;
}

.activity-list::-webkit-scrollbar-track {
    background-color: $border;
    border-radius: 2rem;
}

.activity-list::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 2rem;
}

.awareness-assessment-sosafe-surveys-root {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 520px) {
    display: block;
    padding-top: 20px;
  }
}

.awareness-assessment-sosafe-surveys-body {
  width: 100%;
  max-width: 1120px;

  @media (max-width: 1200) {
    max-width: 1004px;
  }

  @media (max-width: 992px) {
    max-width: 896px;
  }

  @media (max-width: 320px) {
    max-width: 304px;
  }
}

.awareness-assessment-sosafe-surveys-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.awareness-assessment-sosafe-surveys-root form {
  width: 100%;
}

.awareness-assessment-sosafe-surveys-header {
  padding: 10px;
}

.awareness-assessment-sosafe-surveys-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.awareness-assessment-sosafe-surveys-question {
  padding: 0;
}

.awareness-assessment-sosafe-surveys-question-content label {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 992px) {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }
}

.awareness-assessment-sosafe-surveys-question-radiogroup-item {
  padding: 16px;
  border: 1px solid var(--sosafe-color-gray--10);
  border-radius: 8px;
  display: flex;
  margin-bottom: 16px;
}

.awareness-assessment-sosafe-surveys-question-radiogroup-item > label {
  cursor: pointer;
}

.awareness-assessment-sosafe-surveys-radiogroup-itemchecked {
  border: 2px solid var(--mantine-color-focus);
}

.awareness-assessment-sosafe-surveys-question-withframe {
  padding: 0;
  margin: 0;
}

.awareness-assessment-sosafe-surveys-page {
  padding: 68px 48px;

  @media (max-width: 820px) {
    padding: 20px;
  }

  @media (max-width: 520px) {
    padding: 10px;
  }
}

.awareness-assessment-sosafe-surveys-question-header {
  text-align: left;
  margin: 0;
  padding-bottom: 32px;
}

.awareness-assessment-sosafe-surveys-panel-title {
  color: var(--mantine-color-default-color, #343A3F);
  overflow-wrap: break-word;
  text-align: left;
  font-family: Inter;
  font-size: 24px;
  margin: 0;
  padding-bottom: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;

  @media (max-width: 520px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  @media (max-width: 992px) {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
}

.awareness-assessment-sosafe-surveys-panel-title span {
  width: 100%;
}

.awareness-assessment-sosafe-surveys-question-description {
  text-align: left;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;

  @media (max-width: 992px) {
    color: #878d96;
    font-size: 12px;
    font-weight: 500;
  }
}

.awareness-assessment-sosafe-surveys-question-radiogroup {
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-style: normal;
  padding: 0;
  gap: 8px;

  @media (max-width: 520px) {
    gap:0;
  }
}

.awareness-assessment-sosafe-surveys-question-radiogroup > div > div {
  display: flex;
  align-items: center;
}

.awareness-assessment-sosafe-surveys-question-radiogroup > div:has(input[type="checkbox"]:not(:checked)) {
  border: 1px solid var(--sosafe-color-gray--10);
  border-radius: 8px;
  padding: 16px;
}

.awareness-assessment-sosafe-surveys-question-radiogroup > div:has(input[type="checkbox"]:checked) {
  border: 2px solid var(--mantine-color-focus);
  border-radius: 8px;
  padding: 16px;
}

.awareness-assessment-sosafe-surveys-radiogroup-materialdecorator {
  position: relative;
  width: 18px;
  height: 18px;
  border: 1px solid #dde1e6;
  border-radius: 100%;
  display: inline-block;

  @media (max-width: 520px) {
    width: auto;
    height: auto;
    border-width: 6px;
  }
}

.awareness-assessment-sosafe-surveys-radiogroup-itemchecked {
  .awareness-assessment-sosafe-surveys-radiogroup-materialdecorator {
    border-color: var(--mantine-color-blue-5);
    border-width: 6px;
  }
}

.awareness-assessment-sosafe-surveys-radiogroup-itemcontrol {
  position: absolute;
  margin-top: 4px;
  margin-left: 4px;
  width: 0;
  height: 0;
}

.awareness-assessment-sosafe-surveys-radiogroup-controllabel {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-left: 10px;
}

.awareness-assessment-sosafe-surveys-radiogroup-controllabel > span {
  color: var(--radio-variants-checked-label, #000);
  font-family: var(--font-family-inter, Inter);
  font-size: var(--font-size-sm, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

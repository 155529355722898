@import "../../scss/theme";

.category-min-height {
  min-height: 14rem;
}

@include media-breakpoint-down(sm) {
  .card-header-down-sm-height {
    height: 180px;
  }
}

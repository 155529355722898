@import '../../scss/theme';

.NextModulePreview {
  opacity: .8;
  position: absolute;
  bottom: -.5rem;
  right: -.5rem;
  cursor: pointer;
  width: 35%;
  height: 35%;
  transition: opacity .4s;
  padding: 1rem;
  overflow: hidden;

  &:hover {
    opacity: 1;
  }

  .content {
    position: relative;
    transform: translate(120%, 0);
    animation: slide 1s ease-out .5s 1 normal forwards;
    background: rgba(black,1);
    width: 100%;
    height: 100%;
  }

  .next-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .next-title {
    position: absolute;
    width: 100%;
    background: rgba(black, .7);
    padding-top: .25rem;
    padding-bottom: .25rem;
    color: white;
    left: 0px;

    &.top {
      top: 0;
    }

    &.bottom {
      bottom: 0;
    }
  }

  .close {
    position: absolute;
    top: .15rem;
    right: .15rem;
  }
}

@keyframes slide {
  from {
      transform: translate(120%, 0);
  } to {
      transform: translate(0, 0);
  }
}

@include media-breakpoint-down(md) {

  .NextModulePreview {
    height: 12rem;
    width: 21rem;
    max-width: 95vw;
    overflow: hidden;
    transform: translate(0, 100%);
    padding: 9px;

    &:hover {
      opacity: 1;
    }

    .content {
      position: relative;
      transform: translate(120%, 0);
      animation: slide 1s ease-out .5s 1 normal forwards;
      background: rgba(black,1);
      width: 100%;
      height: 100%;
    }

    .next-play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .next-title {
      position: absolute;
      width: 100%;
      background: rgba(black, .7);
      padding-top: .25rem;
      padding-bottom: .25rem;
      color: white;
      left: 0px;

      &.top {
        top: 0;
      }

      &.bottom {
        bottom: 0;
      }
    }

    .close {
      position: absolute;
      top: .15rem;
      right: .15rem;
    }
  }
}

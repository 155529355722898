.sosafe-PolicyView-pdfWrapper {
  width: 44rem;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.sosafe-PolicyView-pdfViewerObject {
  flex-grow: 1;
  width: 100%;
  margin: 1rem 0;
  box-shadow: var(--sosafe-shadows-default);
  min-height: 480px;
}

.sosafe-PolicyView-pdfViewerFallbackContainer {
  display: flex;
  flex-flow: column wrap;
  margin: 1rem 0;
  width: 100%;
  min-height: 480px;
  background-color: var(--sosafe-color-lightGray);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.sosafe-PolicyView-pdfViewerFallbackWarning {
  width: 100%;
  padding: 0 3rem 3rem;
}

.sosafe-PolicyView-policyName {
  font-size: var(--sosafe-typo-h2);
  font-weight: bold;
  line-height: 1.5rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

.sosafe-PolicyView-policyDescription {
  font-size: var(--sosafe-typo-base);
  font-weight: normal;
  line-height: 1rem;
  text-align: center;
}

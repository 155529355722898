// bootstrap-like css-rules for object attributes

// Object-Fit Attributes
.object-fit-contain { object-fit: contain; }
.object-fit-cover { object-fit: cover; }
.object-fit-fill { object-fit: fill; }
.object-fit-none { object-fit: none; }
.object-fit-scale-down { object-fit: scale-down; }
.object-fit-auto { object-fit: auto; }

// Object-Position Attributes
.object-position-bottom { object-position: bottom; }
.object-position-center, .object-position-center-center { object-position: center; }
.object-position-left { object-position: left; }
.object-position-right { object-position: right; }
.object-position-top { object-position: top; }

.object-position-bottom-left, .object-position-left-bottom { object-position: bottom left; }
.object-position-bottom-right, .object-position-right-bottom { object-position: bottom right; }
.object-position-right-center, .object-position-center-right { object-position: center right; }
.object-position-left-center, .object-position-center-left { object-position: center left; }
.object-position-top-left, .object-position-left-top { object-position: top left; }
.object-position-top-right, .object-position-right-top { object-position: top right; }

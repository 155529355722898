@import '../../scss/theme';

.custom-form-control {
    border-right: 0;

    &::-ms-clear {
        display: none;
    }
}

.custom-form-control.valid {
    border: 1px solid var(--primary);
    border-right: 0;
}

.form-group {
    position: relative;
}

.form-control-valid-icon {
    color: var(--primary);
    position: absolute;
    right: .5rem;
    bottom: 25%;
    width: auto;
    height: 25px;
    transform: translate(0, 50%);
}

.validation-append {
    .input-group-text {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-color: white;
        border-left: 0;

        svg {
            display: none;
        }

        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    &.valid {
        .input-group-text {
            color: var(--primary);
            text-align: center;
            border: 1px solid var(--primary);

            svg {
                width: 20px;
                height: 20px;
                display: block;
            }
        }
    }

    &.disabled {
        background-color: #e9ecef;
    }
}

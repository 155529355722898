.moduleHub {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.lessons {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.categoryDropdown {
  width: 100%;
  display: flex;
  justify-content: center;
}

.librarySection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;
}

.categories {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  margin-bottom: 48px;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.divider {
  padding: 20px;
  color: #697077;
  margin-bottom: 8px;
}

.dividerText {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #dde1e6;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.dividerText span {
  background: var(--sosafe-color-coldWhite);
  padding: 0 10px;
}

@media screen and (max-width: 1300px) {
  .moduleHub {
    width: 796px;
  }

  .divider {
    width: 796px;
  }
}

@media (min-width: 800px) and (max-width: 1190px) {
  .moduleHub {
    width: 100%;
    max-width: 648px;
    min-width: 448px;
  }

  .divider {
    width: auto;
  }
}

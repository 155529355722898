.content {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: var(--sosafe-spacing-16);
  margin-bottom: var(--sosafe-spacing-24);
}

.badges {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.line {
  margin: var(--sosafe-spacing-24) 0;
  border-top: 1px solid var(--cool-gray-20);
  width: 100%;
}

.line:last-child {
  display: none;
}

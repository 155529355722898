.content {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  align-items: center;
  gap: 40px;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: var(--sosafe-spacing-32);
  background-color: var(--sosafe-color-coldWhite);
}

.content .container {
  width: 100%;
  max-width: 1920px;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .content .container {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .content {
    padding: var(--sosafe-spacing-16);
  }
}

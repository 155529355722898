@font-face {
  font-family: Manrope, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
    sans-serif;
}

.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding-top: 60.5px;
  padding-bottom: 0;
}

.subHeading {
  color: var(--mantine-color-text, #000);
  margin-bottom: var(--sosafe-spacing-12);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.1px;
}

.text {
  margin-bottom: 20px;
  color: var(--mantine-color-gray-7, #495057);
  font-family: var(--font-family-inter, Inter);
  font-size: var(--font-size-sm, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  text-align: center;
}

.welcome .actionBar button {
  background-color: var(--sosafe-color-primary--100);
  border: 1px solid var(--sosafe-color-primary--100);
  color: var(--button-primary-color-text, #fff);
}

.actionBar {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  border-top: 1px solid var(--sosafe-color-gray--10);
  margin-top: auto;
  padding: var(--sosafe-spacing-16);
}

.button {
  font-weight: 500;
}

.contentImage {
  height: 148px;
}

@media screen and (max-width: 1300px) {
  .subHeading,
  .text {
    padding: 0 20px;
  }
}

@media screen and (max-width: 450px) {
  .welcome {
    padding-top: var(--sosafe-spacing-64);
  }

  .subHeading,
  .text {
    padding: 0 10px;
  }

  .contentImage {
    height: 104px;
  }

  .subHeading {
    font-size: 14px;
    line-height: 20px;
  }

  .text {
    font-size: 12px;
    line-height: 16px;
  }
}

@import '../../scss/theme';

.progress-bar {
  border-radius: 4px;
  height: 1rem;
  background-color: rgba(199, 210, 218, .4);
  width: 100%;
  display: flex;
  position: relative;

  &.progress-bar-sm {
    height: 0.7rem;
  }
}

.animate {
  .progress-bar-indicator {
    transition: transform 1s;
  }

  .progress-label {
    transition: transform 1s;
  }

  &.delay {
    .progress-bar-indicator {
      transition: transform 1s;
      transition-delay: .5s;
    }

    .progress-label {
      transition: transform 1s;
      transition-delay: .5s;
    }
  }
}

.progress-bar-indicator {
  width: 100%;
  height: 100%;
  margin-right: auto;
  border-radius: 4px;
  background-color: $primary;
  transform-origin: center left;
}

.progress-label {
  width: 100%;
  position: absolute;
  overflow-wrap: normal;
  height: 1em;
  left: 0;

  span {
    position: absolute;
    left: 0;
  }

  &.top {
    top: 0;
    span {
      transform: translate(-100%, -90%);
    }
  }

  &.bottom {
    bottom: 0;
    span {
      transform: translate(-100%, 90%);
    }
  }
}

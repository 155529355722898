@import "../../scss/theme";

.FaqPage {
  height: 100%;
  width: 100%;
  margin: 0 auto auto;
  padding: 0 0.25rem;
}

.accordion-item {
  padding: 1rem;

  &:hover {
    background-color: #eeecec;
  }

  border-top: 2px solid $border;
}

.accordion-item-octicon {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}

.item-desc a {
  color: var(--mantine-color-anchor);
}

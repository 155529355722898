.ModuleFeedback-message {
    display: none;
    flex-direction: column;
}


.ModuleFeedback-stars {
    display: flex;
	justify-content: center;
    color: var(--sosafe-color-gray--50);
    margin: 0 0.75rem;
}
.ModuleFeedback-stars:hover .ModuleFeedback-starIcon {
    fill: var(--secondary);
    color: var(--secondary);
}

.ModuleFeedback-stars .ModuleFeedback-starIcon:hover ~ .ModuleFeedback-starIcon {
    fill: none;
    color: inherit;
}

.ModuleFeedback-submit-button {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

@media (max-width: 576px) { /* equivalent to grid-breakpoint-sm */
    .ModuleFeedback-stars {
        order: -1;
	    flex: 1 0 100%;
        margin: 0;
    }
}



.ModuleFeedback-recommendations {
    display: flex;
    justify-content: center;
    margin: 0 0.75rem;
}
.ModuleFeedback-recommendationNumberWrapper {
    padding: .3125rem
}
.ModuleFeedback-recommendationNumber {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 2.8rem;
    height: 2.8rem;
    font-weight: bold;
    color: var(--sosafe-color-gray--50);
    border: 0.125rem solid var(--sosafe-color-gray--50);
    border-radius: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: transparent;
}

.ModuleFeedback-recommendationNumberWrapper.is-selected > .ModuleFeedback-recommendationNumber,
.ModuleFeedback-recommendations:hover .ModuleFeedback-recommendationNumber {
    border: none;
    color: var(--sosafe-color-white);
}
.ModuleFeedback-recommendationNumberWrapper.is-selected:nth-child(-n+9) > .ModuleFeedback-recommendationNumber,
.ModuleFeedback-recommendations:hover .ModuleFeedback-recommendationNumberWrapper:nth-child(-n+9) > .ModuleFeedback-recommendationNumber {
    background-color: var(--sosafe-chartColor-isoRed);
}
.ModuleFeedback-recommendationNumberWrapper.is-selected:nth-child(n+10):nth-child(-n+12) > .ModuleFeedback-recommendationNumber,
.ModuleFeedback-recommendations:hover .ModuleFeedback-recommendationNumberWrapper:nth-child(n+10):nth-child(-n+12) > .ModuleFeedback-recommendationNumber {
    background-color: var(--sosafe-chartColor-isoYellow);
}
.ModuleFeedback-recommendationNumberWrapper.is-selected:nth-child(n+13) > .ModuleFeedback-recommendationNumber,
.ModuleFeedback-recommendations:hover .ModuleFeedback-recommendationNumberWrapper:nth-child(n+13) > .ModuleFeedback-recommendationNumber {
    background-color: var(--sosafe-chartColor-isoGreen);
}

.ModuleFeedback-recommendations .ModuleFeedback-recommendationNumberWrapper:hover ~ .ModuleFeedback-recommendationNumberWrapper > .ModuleFeedback-recommendationNumber {
    color: var(--sosafe-color-gray--50) !important;
    border: 0.125rem solid var(--sosafe-color-gray--50) !important;
    background-color: transparent !important;
}

@media (max-width: 1200px) { /* equivalent to grid-breakpoint-xl */
    .ModuleFeedback-recommendations {
        order: -1;
	    flex: 1 0 100%;
        margin: 0;
    }
}
@media (max-width: 768px) { /* equivalent to grid-breakpoint-md */
    .ModuleFeedback-recommendations {
        flex-wrap: wrap;
        order: 3;
        margin-top: 1rem;
    }
    .ModuleFeedback-recommendationSelect {
        flex-direction: column;
    }
    .ModuleFeedback-recommendationLegend {
        align-self: flex-start;
        margin-top: 0.25rem;
    }
    .ModuleFeedback-breakFlex {
	    flex-basis: 100%;
        height: 0;
    }
}

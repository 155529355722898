@import '../../scss/theme';

.badge-shield {
    animation: badgeEnter 0.8s ease-out;
    
    @keyframes badgeEnter {
        0% {
            transform: scale(0);
            opacity: 0;
        }

        30% {
            transform: scale(0);
            opacity: 0;
        }
            
        80% {
            transform: scale(1.2);
            opacity: 1;
        }

        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
}

.reward-modal {

    .reward-modal-content { 
        max-width: 360px;
        
        // Fade right
        .fade-right-enter {
            opacity: 0;
            transform: translateX(-100%);
        }
        .fade-right-exit {
            opacity: 1;
            transform: translateX(0%);
        }
        .fade-right-enter-active {
            opacity: 1;
            transform: translateX(0);
        }
        .fade-right-exit-active {
            opacity: 0;
            transform: translateX(100%);
        }
        .fade-right-enter-active {
            transition: opacity 600ms, transform 400ms;
        }
        
        .fade-right-exit-active {
            transition: opacity 300ms, transform 400ms;
        }
        
        // Fade scale
        .fade-scale-enter {
            opacity: 0;
            transform: scale(0);
        }
        .fade-scale-exit {
            opacity: 1;
            transform: scale(1);
        }
        .fade-scale-enter-active {
            opacity: 1;
            transform: scale(1);
        }
        .fade-scale-exit-active {
            opacity: 0;
            transform: scale(0);
        }
        .fade-scale-enter-active {
            transition: opacity 600ms, transform 400ms;
        }
        
        .fade-scale-exit-active {
            transition: opacity 600ms, transform 400ms;
        }
        
        // Fade scale FAST
        .fade-scale-fast-enter {
            opacity: 0;
            transform: scale(0);
        }
        .fade-scale-fast-exit {
            opacity: 1;
            transform: scale(1);
        }
        .fade-scale-fast-enter-active {
            opacity: 1;
            transform: scale(1);
        }
        .fade-scale-fast-exit-active {
            opacity: 0;
            transform: scale(0);
        }
        .fade-scale-fast-enter-active {
            transition: opacity 200ms, transform 200ms;
        }
        
        .fade-scale-fast-exit-active {
            transition: opacity 200ms, transform 200ms;
        }
  
    }
}


.tour-overlay {
  left: 0;
  top: 0;
  z-index: 1000;
}

.tour-tooltip {
  position: fixed;
  max-width: 400px;
  max-height: 400px;
  padding: 1.5rem 1.5rem;

  &.hide {
    opacity: 0;
  }

  &.show {
    opacity: 1;
  }

  transition: opacity .5s;
}

.tour-image {
  height: 200px;
}

@import '../../../scss/theme';

.notification-menu {
  width: 350px;
  max-width: 95vw;

  .item:hover {
    background-color: rgba($light, .2);
  }

  .item {

    &.type-0 {
&.unread {
        -webkit-box-shadow: inset 5px 0px 0px 0px $border;
        -moz-box-shadow: inset 5px 0px 0px 0px $border;
        box-shadow: inset 5px 0px 0px 0px $border;
      }
    }
    &.type-1 {
      &.unread {
        -webkit-box-shadow: inset 5px 0px 0px 0px $danger;
        -moz-box-shadow: inset 5px 0px 0px 0px $danger;
        box-shadow: inset 5px 0px 0px 0px $danger;
      }
    }
    &.type-2 {
      &.unread {
        -webkit-box-shadow: inset 5px 0px 0px 0px $success;
        -moz-box-shadow: inset 5px 0px 0px 0px $success;
        box-shadow: inset 5px 0px 0px 0px $success;
      }
    }
  }
}

.notification-bubble::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $primary;
  left: 38px;
  top: 4px;
  z-index: 1;
}

.LoadingPage {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.LoadingContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


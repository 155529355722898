@import '../../scss/theme';

.responsive-picture-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 101;
}

.loading-wrapper {
  &.small {
    width: 32px;
    height: 32px;

    &.use-label {
      height: calc(32px + 1.5rem);
      width: calc(32px + 2rem);
    }
  }
  &.medium {
    width: 64px;
    height: 64px;

    &.use-label {
      height: calc(64px + 1.5rem);
      width: calc(64px + 2rem);
    }
  }
  &.large {
    width: 96px;
    height: 96px;

    &.use-label {
      height: calc(96px + 1.5rem);
      width: calc(96px + 2rem);
    }
  }

}

.loading-label {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.loading-inner-svg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.loading-shield {
  animation: loadingBounce 2000ms linear infinite normal forwards;
}

.loading-stroke {
  animation: loadingRotateStroke 2000ms linear infinite normal forwards;
}

.stroke-primary {
  stroke: $primary;
}

.fill-primary {
  fill: $primary;
}

@keyframes loadingBounce {
  0% {
    transform: translate(-50%, 0) scale(0);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1.275);
  }

  50% {
    transform: translate(-50%, 0) scale(.9);
    animation-timing-function: cubic-bezier(.6, -.28, .735, .045);
  }

  to {
    transform: translate(-50%, 0) scale(0)
  }
}

@keyframes loadingRotateStroke {
  0% {
    stroke-dasharray: 500;
    stroke-dashoffset: 2000;
    animation-timing-function: cubic-bezier(.6, -.28, .735, .045);
  }

  to {
    stroke-dasharray: 500;
    stroke-dashoffset: 4000;
  }
}

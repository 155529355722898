@import '_theme.scss';

@function getSpace($key: 1) {
  @return map-get($spacers, $key);
}

/* negative margin */

@for $i from 1 through 5 {
  $space: getSpace($i) * -1;
  .n-m-#{$i} { margin: $space; }
  .n-ml-#{$i} { margin-left: $space; }
  .n-mr-#{$i} { margin-right: $space; }
  .n-mx-#{$i} { margin-left: $space; margin-right: $space; }
  .n-mt-#{$i} { margin-top: $space; }
  .n-mb-#{$i} { margin-bottom: $space; }
  .n-my-#{$i} { margin-top: $space; margin-bottom: $space; }
}

.n-mx-075rem { margin-left: -0.75rem; margin-right: -0.75rem; }

@import '../../scss/theme';

.achievements-page {

  .level-border-bottom {
    border-width: 2px !important;
  }

  .level-shield {
    max-width: 10rem;
    width: 40%;
    max-height: 130px;
  }
  @include media-breakpoint-down(sm) {
    .level-shield {
      max-width: 8rem;
      width: 30%;
      max-height: 110px;
    }
  }
}
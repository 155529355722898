.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 8px;
  margin-bottom: 8px;
}

.centerText {
  text-align: center;
}

.footer {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
}

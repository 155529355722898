$button-text: #fffffe;
$danger: #c6433c;
$dark: #494949;
$footer-dark: #545959;
$info: #3fc3ee;
$light: #acb0b4;
$primary-scorm: #00c39b; // primary and primary-scorm have to be (slightly) different in
$primary: #00c39a; // order for our backend to properly replace branding
$question: #87adbd;
$salmon: #f6af7e;
$secondary: #05668e;
$success: #01c39b;
$warning: #ffa837;
$icon-gray: #494949;
$border: #e8e5e5;
$pill-orange: #b1500c;
$pill-blue: #336f99;
$pill-purple: #639;
$link-color: $secondary;

// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);
$container-max-widths: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);
$secondary-hover: theme-color-level(secondary, 4);
$grid-gutter-width: 1.5rem;
$hr-border-color: #e8e5e5;
$hr-border-width: 2px;

// Buttons

$btn-white-space: nowrap;

// Cards

$card-spacer-x: 1rem;
$card-spacer-y: 0.75rem;
$card-border-color: $light;
$card-border-radius: 4px;
$card-border-width: 0;

// Components

$border-color: $border;
$list-group-item-padding-y: 0.75rem !default;
$list-group-item-padding-x: 0 !default;
$nav-pills-link-active-color: red;
$logoHeight: var(--dimension-18);
$fallbackLogoWidth: 145px;
$logoMaxWidth: 245px;
$logoMobileWidth: 102px;

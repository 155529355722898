.optin-overlay {
  left: 0;
  top: 0;
  z-index: 1000;
  background-color: rgba(black, .5);
}

.optin-tooltip {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem 1.25rem;
  width: 500px;
  max-width: 95%;
  max-height: 95%;
  overflow: auto;

  &.hide {
    opacity: 0;
  }

  &.show {
    opacity: 1;
  }

  transition: opacity .5s;
}

// Fade right
.fade-enter {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 600ms;
}

.fade-exit-active {
  transition: opacity 600ms;
}

@import "scss/theme";
@import "bootstrap/scss/root";
@import "scss/customized-bootstrap-reboot";
@import "bootstrap/scss/images";
@import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group";
@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/jumbotron";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/media";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/print";
@import "scss/misc";
@import "scss/grid";
@import "scss/spacing";
@import "scss/buttons";
@import "scss/forms";
@import "scss/svg";
@import "scss/arab";
@import "scss/scrollbar";
@import "@mantine/core/styles.css";
@import '@sosafe-platform-engineering/fe-lib-ui-mantine-react/dist/assets/fonts/inter.css';
@import '@sosafe-platform-engineering/fe-lib-ui-mantine-react/dist/index.css';
@import '@sosafe-aws/fe-lib-maintenance-pages/dist/index.css';


body {
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100% !important;
  width: 100%;
  overflow: hidden;
}

input:not(button, input):focus,
a:focus,
[role="button"]:focus,
button:focus {
  outline: 2px solid var(--mantine-color-blue-8, #05668e) !important;
  outline: 2px solid var(--mantine-color-blue-8, #018678);
}


// Expose this classes to be used in the manager when creating INFO WIDGET
.btn-primary {
  color: white;
  background-color: var(--sosafeThemed-color-primary, #05668e);
  border-color: var(--sosafeThemed-color-primary, #05668e);

  &:hover {
    background-color: var(--sosafeThemed-color-primary--120, #045171);
    border-color: var(--sosafeThemed-color-primary--120, #045171);
  }

  &:focus {
    background-color: var(--sosafeThemed-color-primary--120, #045171);
    border-color: var(--mantine-color-blue-8, #045171);
    box-shadow: 0 0 0 0.2rem var(--mantine-color-blue-8, #cde0e8);
    outline: 2px solid var(--mantine-color-blue-8, #018678);
  }
}

.btn-secondary {
  background-color: var(--sosafeThemed-color-secondary, #02a896);
  border-color: var(--sosafeThemed-color-secondary, #02a896);

  &:hover {
    background-color: var(--sosafeThemed-color-secondary--120, #018678);
    border-color: var(--sosafeThemed-color-secondary--120, #018678);
  }

  &:focus {
    background-color: var(--sosafeThemed-color-secondary--120, #018678);
    border-color: var(--mantine-color-blue-8, #018678);
    box-shadow: 0 0 0 0.2rem var(--mantine-color-blue-8, #ccedea);
    outline: 2px solid var(--mantine-color-blue-8, #018678);
  }
}

.btn:focus {
  box-shadow: 0 0 0 0.2rem var(--mantine-color-blue-8, #cde0e8);
  outline: 2px solid var(--mantine-color-blue-8, #cde0e8);
}

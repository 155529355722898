@import "../../../scss/theme";

.App-header {
  $headerPadding: 14px;

  color: var(--sosafe-color-gray--100);
  position: relative;
  z-index: 999;

  &.white-theme {
    background-color: var(--sosafe-color-white);
  }

  &.gray-theme {
    background-color: var(--sosafe-color-gray--20);
  }

  .header-inner {
    z-index: 2;
    padding: $headerPadding;

    // background-color: $white;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;

    & .pulse {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        background-color: $primary;
        opacity: 0.5;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        animation: pulse 1s alternate infinite ease-in-out;
      }
    }
  }

  .header-menu {
    // background-color: $white;
    z-index: -1000;
    width: 100%;
    position: absolute;
    padding: $headerPadding;
    bottom: 0;
    transition: transform 0.4s;
    height: calc(100vh - #{2 * $headerPadding + $logoHeight});
    overflow-y: auto;

    &.menu-open {
      transform: translate(0, 100%);
      background-color: #fff;
    }

    &.menu-closed {
      transform: translate(0, -60px);
    }

    .active {
      font-weight: bold;

      span {
        font-weight: bold;
      }
    }

    .header-menu-item,
    .header-submenu-item,
    .header-submenu .header-submenu-item:first-child {
      position: relative;

      &:hover {
        background-color: $border;

        &::before,
        &:last-child::after,
        .header-submenu .header-submenu-item:first-child::before {
          width: 100%;
        } // widen this and next divider on hover
        + ::before {
          width: 100%;
        }
      }

      &::before,
      &:last-child::after {
        content: "";
        position: absolute;
        display: block;
        background-color: $border;
        top: 0;
        height: 1.4px; //to 'trick' retina displays to display a 3px line
        left: 50%;
        transform: translateX(-50%);
        width: calc(
          100% - (1rem + 24px) - 1rem
        ); // 1 rem + 24px = logo width, the last 1 rem is outer padding
      }

      :focus,
      .focus {
        + ::before,
        &::after,
        + .header-submenu .header-submenu-item:first-child::before {
          background-color: transparent;
        }
      }

      &:last-child::after {
        top: 100%;
      }
    }

    .header-submenu {
      background-color: $white;

      .header-submenu-item {
        padding-left: calc(1rem + 24px); //beginning of text
        padding-right: calc(1rem + 24px); //beginning of text
        &:hover {
          background-color: $border;

          &::before {
            width: 100%;
            left: 0;
          } // widen this and next divider on hover
          + ::before {
            width: 100%;
            left: 0;
          }
        }

        &:first-child:hover::before {
          left: 50%;
        }

        &::before {
          //custom divider width for child-items
          left: calc(1rem + 24px); // set to beginning of text
          transform: translateX(0);
          width: calc(
            100% - (1rem + 24px) - (1.5rem + 8px)
          ); // 1.5rem + 8px is half the chevron
        }
      }
    }
  }

  .header-submenu-inner {
    padding-left: 2rem;
  }

  .header-divider {
    height: $logoHeight;
    width: 1px;
    border-right: solid 1px var(--sosafe-color-gray--50);
  }

  .header-link {
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    font-weight: normal;

    &.active {
      font-weight: bold;
    }

    &.disabled {
      color: $light;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

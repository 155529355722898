.header-logo {
  height: var(--dimension-18);
  max-width: 245px;
  cursor: pointer;

  img {
    max-width: 245px;
  }

  &.fallback-image {
    width: 145px;
  }
}

@media screen and (max-width: 480px) {
  .header-logo {
    max-width: 102px;

    img {
      max-width: 102px;
    }

    &.fallback-image {
      width: 192px;
    }
  }
}

.card {
  width: 456px;
  height: 372px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.headerImage {
  width: 100%;
  height: 184px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
}

.headerImage img {
  width: 100%;
  height: auto;
  align-self: center;
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex-grow: 1;
}

.label {
  display: flex;
  justify-content: space-between;
  line-height: 24px;
  margin: 12px 0 0;
  order: 2;
}

.label h3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.label h3 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}

.details {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  order: 1;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  gap: 12px;
  order: 1;
}

.tags {
  display: flex;
  gap: 8px;
}

.highLight {
  border: 4px solid var(--sosafe-color-primary--100);
  border-radius: 16px;
}

.duration {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: var(--cool-gray-60);
}

.icon {
  margin: 0 4px 0 0;
}

.icon,
.durationText {
  color: var(--mantine-color-gray-7);
}

.progressBarMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  order: 3;
}

.progressBar {
  display: flex;
  flex-grow: 1;
}

.hideProgressBar {
  display: flex;
  justify-content: flex-end;
  order: 3;
}

.awarenessAssessmentContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  order: 3;
}

.moduleLocked {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  background: rgb(0 0 0 / 50%);
  justify-content: center;
  align-items: center;
}

.doNotDisplay {
  display: none;
  z-index: 0;
}

.lockerBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.lockerIcon {
  width: 36px;
  height: 36px;
  padding: 7px;
  border-radius: 19.5px;
  border: 1px solid var(--badge-variants-neutral-border, #dee2e6);
  background: #f1f3f5;
}

@media screen and (max-width: 1300px) {
  .card {
    width: 380px;
  }
}

@media screen and (max-width: 1190px) {
  .card {
    width: 648px;
  }

  .progressBar {
    display: flex;
    flex-grow: 0;
    flex-basis: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1190px) {
  .card {
    width: 648px;
  }
}

@media (min-width: 801px) and (max-width: 992px) {
  .card {
    width: 100%;
    max-width: 480px;
  }
}

@media screen and (max-width: 480px) {
  .card {
    width: 100%;
    max-width: 648px;
    padding: 10px;
    padding-top: 0;
    height: auto;
  }

  .progressBar {
    display: flex;
    flex-grow: 0;
    flex-basis: 80%;
  }

  .headerImage {
    height: 160px;
  }

  .label h3 {
    font-size: 18px;
  }
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .card {
    height: auto;
  }

  .duration {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
  }
}

@media screen and (max-width: 393px) {
  .card {
    width: 100%;
    max-width: 390px;
  }
}

@media screen and (max-width: 333px) {
  .card {
    width: 100%;
    max-width: 288px;
  }
}

.header {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: visible;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  position: relative;
  height: auto;
  align-items: center;
  z-index: 100;
  padding: var(--sosafe-spacing-32) 0;
  border-bottom: var(--sosafe-color-gray--10) 1px solid;
  background-color: var(--sosafe-color-coldWhite);
}

.header .wrapper {
  max-width: 1920px;
}

.wrapper {
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  overflow: visible;
  align-self: center;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  margin-right: var(--sosafe-spacing-32);
  align-self: center;
}

.logo:hover {
  cursor: pointer;
}

.leftMenu {
  display: flex;
  flex-direction: row;
}

.leftMenuNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  gap: var(--sosafe-spacing-16);
}

.leftMenuItem {
  font-weight: 400;
}

.leftMenuItemActive {
  font-weight: 500;
}

.rightMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--sosafe-spacing-32);
  position: relative;
}

.rightAccount {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--sosafe-spacing-4);
}

.name {
  margin: 0;
}

.icon {
  cursor: pointer;
}

.chevIcon {
  margin-left: var(--sosafe-spacing-4);
}

.dropBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: black;
  font-family: inherit;
  margin: 0;
  font-size: var(--dimension-10);
}

.dropBtn:focus-visible {
  outline: none;
}

.dropMenuBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  gap: var(--sosafe-spacing-8);
}

.dropBtn:hover {
  text-decoration: none;
  color: black;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  width: 340px !important;
}

.mobileNav {
  display: none;
}

.menuList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: var(--sosafe-spacing-16);
}

@media screen and (min-width: 480px) {
  .header .wrapper {
    width: calc(100vw - 64px);
  }
}

@media screen and (min-width: 1440px) {
  .header .wrapper {
    max-width: calc(100vw - 80px);
  }
}

@media screen and (max-width: 480px) {
  .leftMenuNav,
  .rightAccount {
    display: none;
  }

  .mobileNav {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .header {
    height: var(--sosafe-spacing-64);
    padding: 0;
  }

  .dropdownMenu {
    margin-top: 80px;
    margin-left: auto;
    width: 280px !important;
  }

  .header .wrapper {
    width: calc(100vw - 30px);
  }
}

@media screen and (max-width: 320px) {
  .header .wrapper {
    width: calc(100vw - 32px);
  }
}

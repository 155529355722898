@import './theme';

.form-control {
  &:focus {
    border-color: rgba($secondary, 0.25);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba($secondary, 0.4);
  }


}

